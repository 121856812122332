export const dayList: { [key: number]: string } = {
  0: "вс",
  1: "пн",
  2: "вт",
  3: "ср",
  4: "чт",
  5: "пт",
  6: "сб",
}

export const orderTimeList = [
  {id: "07:00 - 09:00", text: "07:00 - 09:00"},
  {id: "09:00 - 11:00", text: "09:00 - 11:00"},
  {id: "11:00 - 13:00", text: "11:00 - 13:00"},
  {id: "13:00 - 15:00", text: "13:00 - 15:00"},
  {id: "15:00 - 17:00", text: "15:00 - 17:00"},
  {id: "17:00 - 19:00", text: "17:00 - 19:00"},
  {id: "19:00 - 21:00", text: "19:00 - 21:00"},
  {id: "21:00 - 23:00", text: "21:00 - 23:00"},
];