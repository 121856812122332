import React              from "react";
import {InputType}        from "./model/inputType";
import cn                 from "classnames";
import styles             from "./Input.module.scss";
import {Label, LabelItem} from "../../ui";


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputType?: InputType;
  label?: string;
  icon?: string | React.ReactNode;
  leftText?: string;
  rightIcon?: React.ReactNode;
  errorMessage?: string;
  subtext?: string | React.ReactNode;
  labels?: LabelItem[];
  hasSpace?: boolean;
  dropDownValue?: string | number;
  onDropDownClick?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, Props>(({
  inputType = InputType.DEFAULT,
  hasSpace = true,
  dropDownValue,
  label,
  leftText,
  rightIcon,
  errorMessage,
  labels,
  subtext,
  icon,
  onDropDownClick = () => {
  }, ...rest
}, ref) => {
  const id = React.useId();

  const handleDropDownClick = () => {
    if (rest.disabled) return;
    onDropDownClick();
  }

  return <div className={cn(styles.wrapper, {[styles.has_space]: hasSpace})}>
    {(!!label || !!labels?.length) && <div className={styles.label_holder}>
      {label && <label htmlFor={id}>{label}</label>}
      {labels && <Label clasNames="mgl-2" labels={labels} />}
    </div>}

    <div className={cn(styles.input_holder, {[styles.disabled]: rest.disabled}, {[styles.error]: !!errorMessage})}
         onClick={rest.onClick}>
      {icon && typeof icon === "string" ? <div className={styles.icon_holder}>{icon}</div> : <>{icon}</>}
      {leftText && <span className={styles.left_text}>{leftText}</span>}
      {inputType === InputType.MAP
        ? <div className={cn(styles.map_value, {[styles.placeholder]: !rest.value})}>
          {!rest.value ? rest.placeholder : rest.value}
        </div>

        : <input
          ref={ref}
          autoComplete="off"
          className={cn(styles.input, {[styles.error]: !!errorMessage})}
          id={id}
          {...rest}
        />}
      {rightIcon && <>{rightIcon}</>}
      {inputType === InputType.DROPDOWN && <div className={styles.dropdown_holder} onClick={handleDropDownClick}>
        {dropDownValue && <span className={styles.dropdown_value}>{dropDownValue}</span>}
        <span className={styles.dropdown_icon}>keyboard_arrow_down</span>
      </div>}
    </div>

    {subtext && <>{typeof subtext === "string" ? <span className={styles.subtext}>{subtext}</span> : <>{subtext}</>}</>}
  </div>
})

export default React.memo(Input);