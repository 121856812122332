import React, {ReactNode, useEffect, useState} from "react";
import {useTranslation}                        from "react-i18next";
import {Icon}                                  from "shared/v12ui";
import {setActionForNative, useNativeHandler}  from "shared/lib";
import {NativeActions}                         from "shared/model";
import styles                                  from "./NoInternetConnection.module.scss";
import {SystemResponse}                        from "../SystemResponse";
import {NewPageLayout}                         from "../newPageLayout";

interface Props {
  children: ReactNode;
}

export default function NoInternetConnection({children}: Props) {
  const {t} = useTranslation();

  const [isOnline, setOnline] = useState(true);

  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (isOnline) return;
    return handleBackClick();
  })

  if (isOnline) {
    return <>{children}</>
  } else {
    return <NewPageLayout
      headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}>
      <div className={styles.wrapper}>
        <SystemResponse
          title={t("lost_connection.title")}
          description={t("lost_connection.description")}
          icon="connection_lost"
        />
      </div>
    </NewPageLayout>
  }
}