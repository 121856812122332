import React, {useState}                                          from "react";
import {useNavigate}                                              from "react-router-dom";
import {InApp}                                                    from "shared/ui";
import {Button, NewButtonColor, NewButtonType}                    from "shared/v12ui";
import {useAppDispatch, useAppSelector}                           from "store";
import {selectMethodType, setIsNotMeFreedom, setRegistrationType} from "store/features";
import {useTranslation}                                           from "react-i18next";
import {RegistrationMethodType}                                   from "services/registration";
import styles                                                     from "./styles.module.scss";


const CloseRegistrationInApp = ({isFreedom}: { isFreedom?: boolean }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const methodType = useAppSelector(selectMethodType);

  const [open, setOpen] = useState(false);

  const handleBackSelectRegistration = () => {
    if (isFreedom) {
      dispatch(setIsNotMeFreedom(true));
    }
    navigate("/registration");
  };

  const handleCloseInApp = () => {
    setOpen(false);
    dispatch(setRegistrationType(null));
  };

  return <>
    <div
      className={styles.close_icon}
      onClick={() => setOpen(true)}
    >
      close
    </div>

    <InApp
      inAppType="image"
      headerText={methodType !== RegistrationMethodType.VERIFICATION
        ? t("in_app.close_registration.title")
        : t("in_app.close_verification.title")}
      headerSubtext={methodType !== RegistrationMethodType.VERIFICATION
        ? t("in_app.close_registration.description")
        : t("in_app.close_verification.description")}
      image="/illustrations/too-busy-1.svg"
      open={open}
      onClose={handleCloseInApp}
    >
      <div className={styles.inApp_buttons}>
        <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={handleCloseInApp}
          text={t("common.cancel")}
        />

        <Button
          hasSpace
          text={t("common.interrupt")}
          onClick={handleBackSelectRegistration}
        />
      </div>
    </InApp>
  </>
}

export default CloseRegistrationInApp;