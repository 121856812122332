import React, {useEffect, useState}                  from "react";
import {useNavigate}                                 from "react-router-dom";
import {useTranslation}                              from "react-i18next";
import axios, {Cancel}                               from "axios";
import {v4 as uuidv4}                                from "uuid";
import {RegistrationType, useDocumentUploadMutation} from "services/registration";
import {useAppDispatch, useAppSelector}              from "store";
import {setBiometricUrl, setRegistrationType}        from "store/features/registration";
import {selectSdkVersion}                            from "store/features/queries";
import {ImageLoadError}                              from "entities/registration";
import {Nullable}                                    from "shared/model";
import {InApp}                                       from "shared/ui";
import {Button}                                      from "shared/v12ui";
import {SsnExistInApp}                               from "../SsnExistInApp";


export enum EUploadStatus {
  INITIAL,
  UPLOADING,
  SUCCESS,
  FAIL
}

export interface IUploadFileResponse {
  status: boolean,
  digest: string,
  name: string,
  extension: string,
  size: number,
  mimetype: string,
  download?: string,
  thumbnail?: string,
  preview?: string,
  message?: string,
}

export interface CancelToken {
  promise: Promise<Cancel>;
  reason?: Cancel;

  throwIfRequested(): void;
}

export interface IUploadFile {
  id: string,
  file: File,
  digest?: string,
  status: EUploadStatus,
  preview?: string,
  download?: string,
  image: null,
  progress: number,
  message?: string,
  size: string,
}

interface IState {
  files: IUploadFile[],
  cancelRequestToken: CancelToken | undefined
}

interface IProps {
  digest: string;
  isLoading: boolean;
  onCropData: () => void;
  onNext: () => void;
  // item: IUploadFile;
  onError?: (errorMessage: string, file?: IUploadFile) => void;
  setDigest: React.Dispatch<React.SetStateAction<Nullable<string>>>;
  setStep: (value: string) => void;
}

function base64ToFile(base64Data: string, fileName: string) {
  // Split the base64 string in data and contentType
  const block = base64Data.split(";");
  const contentType = block[0].split(":")[1];
  const realData = block[1].split(",")[1];

  // Convert it to a blob to be able to construct a File
  const blob = b64toBlob(realData, contentType);

  // Create a file from the blob
  return new File([blob], fileName, {type: contentType, lastModified: Date.now()});
}

function b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}

export const formatFileSize = (size: number) => {
  let size_name = "upload_file_size.b";

  if (size > 1024) {
    size = size / 1024.0;
    size_name = "upload_file_size.kb";
  }
  if (size > 1024) {
    size = size / 1024.0;
    size_name = "upload_file_size.mb";
  }

  return `${size.toFixed(2)} ${size_name}`
}

const UploadComponent = ({
  digest,
  isLoading,
  onCropData,
  setStep,
  setDigest,
  onNext = () => {
  },
  onError = () => {
  },
}: IProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sdkVersion = useAppSelector(selectSdkVersion);
  const [{files, cancelRequestToken}, setState] = useState<IState>({
    files:              [],
    cancelRequestToken: undefined,
  });
  const [item, setItem] = useState<Nullable<IUploadFile>>(null);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [onlyManual, setOnlyManual] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const [ssnExist, setSsnExist] = useState(false);
  const [ssnBlockedInApp, setSsnBlockedInApp] = useState(false);

  const [error, setError] = useState(false);

  const [uploadDocument, {data, isLoading: documentIsLoading}] = useDocumentUploadMutation();

  const [croppedDigest, setCroppedDigest] = useState<Nullable<string>>(null);

  const moveToManualRegistration = () => {
    navigate("/manual");
    setOnlyManual(false);
    dispatch(setRegistrationType(RegistrationType.MANUAL));
  }

  const handleTryAgain = () => {
    setDigest(null);
    setStep("1");
    setError(false);
    setTryAgain(false);
  }

  const handleSsnExist = () => {
    setDigest(null);
    setStep("1");
    setSsnExist(false);
  }

  const handleCancel = () => {
    setSsnBlockedInApp(false);
  }

  useEffect(() => {
    if (digest) {
      const newItem = {
        id:       uuidv4(),
        file:     base64ToFile(digest, "cropped_photo"),
        status:   EUploadStatus.INITIAL,
        image:    null,
        progress: 0,
        size:     formatFileSize(base64ToFile(digest, "cropped_photo").size),
      };

      setItem(newItem);

    }
  }, [digest]);

  useEffect(() => {
    if (item) {
      upload(item);
    }
  }, [item]);

  useEffect(() => {
    if (croppedDigest && sdkVersion) {
      uploadDocument({document_front: croppedDigest, system_version: sdkVersion});
      setLoadingProcess(false);
    }
  }, [croppedDigest, sdkVersion]);

  const handleSuccess = (data: string) => {
    dispatch(setBiometricUrl(data));
    dispatch(setRegistrationType(RegistrationType.DOCUMENT));
    onNext();
  }

  useEffect(() => {
    if (data && data?.errors?.profile === "document_only_manual") {
      return setOnlyManual(true);
    }
    if (data && data?.errors?.profile === "document_try_again") {
      return setTryAgain(true);
    }
    if (data && data?.errors?.profile === "ssn_exists") {
      return setSsnExist(true);
    }
    if (data && data?.errors?.profile === "ssn_blocked") {
      return setSsnBlockedInApp(true);
    }
    if (data && data?.content?.url) {
      handleSuccess(data.content.url)
    }
  }, [data]);

  const upload = async function (item: IUploadFile) {
    try {
      setState(prevState => ({
        ...prevState,
        cancelRequestToken: axios.CancelToken.source().token,
      }));
      item.status = EUploadStatus.UPLOADING;
      const formData = new FormData();
      formData.append("file", item.file);
      formData.append("file", item.file, item.file.name);

      const response = await axios.post(`${process.env.REACT_APP_UPLOAD_URL}`, formData, {
        cancelToken: cancelRequestToken,
      });

      const responseData = response.data as IUploadFileResponse;

      if (responseData.status) {
        item.digest = responseData.digest;
        item.status = EUploadStatus.SUCCESS;
        if (responseData.thumbnail !== undefined) {
          item.download = responseData.thumbnail;
        } else {
          item.download = responseData.download;
        }
        setCroppedDigest(item.digest);
      } else {
        files.splice(files.indexOf(item), 1);
      }
    } catch (error: unknown) {
      item.status = EUploadStatus.FAIL;
      setError(true);
      if (error && typeof error === "object" && "message" in error) {
        onError((error as { message: string }).message, item);
      } else {
        onError("OSHIBKA", item);
      }
    }
    setState(prevState => ({
      ...prevState,
      cancelRequestToken: undefined,
    }));
  }

  const handleOnClick = () => {
    setLoadingProcess(true);
    onCropData();
  }

  return <>
    <Button
      text={t("component.cropper.button")}
      disabled={isLoading || loadingProcess || documentIsLoading}
      loading={documentIsLoading}
      onClick={handleOnClick}
      hasSpace
    />

    <InApp
      inAppType="image"
      image="/illustrations/no-conversation-3.svg"
      headerText={t("in_app.document_error.title")}
      headerSubtext={t("in_app.document_error.description_2")}
      open={onlyManual}
      onClose={moveToManualRegistration}
    >
      <Button
        hasSpace
        text={t("in_app.document_error.buttons.manual")}
        onClick={moveToManualRegistration}
      />
    </InApp>

    <InApp
      inAppType="image"
      image="/illustrations/no-conversation-3.svg"
      headerText={t("in_app.document_error.title")}
      headerSubtext={t("in_app.document_error.description_1")}
      open={tryAgain}
      onClose={handleTryAgain}
    >
      <Button
        hasSpace
        text={t("in_app.document_error.buttons.try_again")}
        onClick={handleTryAgain}
      />
    </InApp>

    <SsnExistInApp open={ssnExist} onClose={handleSsnExist} ssn={""} />

    <InApp
      inAppType="image"
      image={"/illustrations/no-conversation-3.svg"}
      headerText={t("in_app.ssn_blocked.title")}
      headerSubtext={t("in_app.ssn_blocked.description")}
      open={ssnBlockedInApp}
      onClose={handleCancel}
    >
      <Button hasSpace text={t("in_app.ssn_blocked.button")} onClick={handleCancel} />
    </InApp>

    {error && <ImageLoadError onClick={handleTryAgain} />}
  </>
}

export default UploadComponent;