import React           from "react";
import {NewList}       from "shared/v12ui";
import {CheckboxInput} from "shared/ui";


interface Props {
  id: number;
  index: number;
  title: string;
  isChecked: boolean;
  currentRef: React.MutableRefObject<HTMLInputElement[]>;
  onParentClick: (event: React.MouseEvent<HTMLDivElement>, index: number) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function ListItemRender({id, index, title, isChecked, currentRef, onChange, onParentClick}: Props) {
  const handleParentClick = (event: React.MouseEvent<HTMLDivElement>) => onParentClick(event, index);

  return <NewList
    title={title}
    onClick={handleParentClick}
    controlIcon={
      <CheckboxInput
        isNew
        ref={(element) => {
          if (element) {
            currentRef.current[index] = element
          }
        }}
        id={`${id}`}
        checked={isChecked}
        onChange={onChange}
      />
    }
  />
}

export default React.memo(ListItemRender)