import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {useNavigate}                           from "react-router-dom";
import {Label}                                 from "shared/ui";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {Nullable}                              from "shared/model";
import {AppMode}                               from "store/features";
import {SkeletonLayout}                        from "entities/skeleton";
import cn                                      from "classnames";
import styles                                  from "./styles.module.scss";


interface Props {
  id: Nullable<number>;
  about?: Nullable<string>;
  appMode: Nullable<string>;
  isViewMode?: boolean;
  isLoading?: boolean;
  moderated?: boolean;
}

const i18n_prefix = "page.specialist.profile.about."

function AboutBlock({
  about,
  appMode,
  id: specialistId,
  isViewMode = false,
  isLoading,
  moderated,
}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleEditClick = () => navigate("/profile/about");

  return <div className={styles.wrapper}>
    {isLoading
      ? <SkeletonLayout height={22} borderRadius={6} width={130} theme="dark" />
      : <span className={styles.header}>{t(`${i18n_prefix}title`)}</span>}

    {isLoading
      ? <div className={styles.skeleton_text}>
        <SkeletonLayout height={20} borderRadius={6} />
        <SkeletonLayout height={20} borderRadius={6} />
        <SkeletonLayout height={20} borderRadius={6} />
        <SkeletonLayout height={20} borderRadius={6} width={"60%"} />
      </div>
      : <div className={styles.text_wrapper}>
        {!moderated && appMode === AppMode.SPECIALIST &&
          <Label labels={[{title: `${t(`${i18n_prefix}on_moderation`)}`, style: "gray"}]} clasNames={styles.label} />}
        <span
          className={cn(styles.text, {[styles.without_text]: !about}, {[styles.moderated]: !moderated && appMode === AppMode.SPECIALIST})}>
        {about && about}
          {appMode === AppMode.CLIENT && !about && t(`${i18n_prefix}empty.client`)}
          {appMode === AppMode.SPECIALIST && !about && t(`${i18n_prefix}empty.specialist`)}
    </span>
      </div>}

    {!specialistId && appMode === AppMode.SPECIALIST && !isViewMode && <>
      {isLoading
        ? <SkeletonLayout height={53} theme="dark" borderRadius={50} />

        : <Button
          text={t(`${i18n_prefix}button.${!about ? "add" : "update"}`)}
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={handleEditClick}
        />}
    </>}
  </div>
}

export default React.memo(AboutBlock);