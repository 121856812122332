import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {InApp}                                 from "shared/ui";
import {useAppDispatch, useAppSelector}        from "store";
import {setActionForNative, useNativeHandler}  from "shared/lib";
import {NativeActions}                         from "shared/model";
import {
  selectCameraInAppIsOpen,
  selectCameraPermission,
  setCameraPermission,
  toggleCameraInApp,
}                                              from "../model/permissionsSlice";


const i18nPrefix = "features.permissions.camera."
export default function CameraRequest() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const cameraPermission = useAppSelector(selectCameraPermission);
  const cameraInAppIsOpen = useAppSelector(selectCameraInAppIsOpen);

  const handleCloseInAppClick = () => {
    dispatch(toggleCameraInApp(false));
  }

  const handleRequestCameraPermission = () => {
    setActionForNative(NativeActions.GIVE_CAMERA_PERMISSION);
  }

  const handleOpenBackCamera = () => {
    dispatch(setCameraPermission(true));
    setActionForNative(NativeActions.OPEN_BACK_CAMERA);
    dispatch(toggleCameraInApp(false));
  }

  React.useEffect(() => {
    if (cameraPermission === null) {
      return setActionForNative(NativeActions.HAS_PERMISSIONS_CAMERA);
    }
  }, [cameraPermission]);

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_CAMERA, data => {
    if (data) {
      return dispatch(setCameraPermission(data.has_permissions));
    }
  });

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.GIVE_CAMERA_PERMISSION, data => {
    if (data.has_permissions) {
      return handleOpenBackCamera();
    } else {
      return dispatch(toggleCameraInApp(false));
    }
  });

  return <InApp
    inAppType="image"
    image="/illustrations/selfie-3.svg"
    headerText={t(`${i18nPrefix}text`)}
    headerSubtext={t(`${i18nPrefix}subtext`)}
    open={cameraInAppIsOpen}
    onClose={handleCloseInAppClick}
  >
    <div className="inApp_buttons">
      <Button
        type="button"
        text={t("common.cancel")}
        buttonType={NewButtonType.WRAPPED}
        buttonColor={NewButtonColor.GRAY}
        onClick={handleCloseInAppClick}
      />
      <Button
        type="button"
        text={t("common.allow")}
        hasSpace
        onClick={handleRequestCameraPermission}
      />
    </div>
  </InApp>
};