import React, {ChangeEvent}                                                  from "react";
import {Button, Input, InputType, NewList}                                   from "shared/v12ui";
import {CheckboxInput, InApp, LabelItem, RadioInput}                         from "shared/ui";
import {Control, Controller, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {CreateState}                                                         from "../model/createState";
import {WorkUnitItemType}                                                    from "../api";
import styles                                                                from "./Steps.module.scss";


interface Props {
  watch: UseFormWatch<CreateState>;
  register: UseFormRegister<CreateState>;
  labels?: LabelItem[];
  workUnits?: WorkUnitItemType[];
  control: Control<CreateState>;
  setValue: UseFormSetValue<CreateState>;
}


function SecondStepOrder({watch, register, control, labels, workUnits, setValue}: Props) {
  const [openSelectUnit, setOpenSelectUnit] = React.useState(false);
  const [openPaymentInfo, setOpenPaymentInfo] = React.useState(false);

  const toggleSelectUnit = () => setOpenSelectUnit(prev => !prev);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[.,]/g, ""); // Удаляем точки и запятые
    if (sanitizedValue.length > 9) {
      input.value = sanitizedValue.slice(0, 9);
    } else {
      input.value = sanitizedValue;
    }
  }

  const handlePaymentsInfoButtonClick = () => {
    setValue("isAppPaymentWay", true);
    setOpenPaymentInfo(false);
  }

  const headerSubtext = <div className={styles.payment_info_subtext}>
    <span>— Проводите мгновенные переводы, если работа уже выполнена</span>
    <span>— Вносите оплату заранее, а подтверждайте после выполнения работ, исключая завышение цен и низкое качество услуг</span>
    <span>— При онлайн оплатах часто доступны кешбэки от Freedom Bank и бонусы от naimi</span>
    <span>— Можно оплатить в кредит или рассрочки, банковской картой, Kaspi, Freedom SuperApp и др. способы</span>
  </div>

  return <>
    <Input
      type="number"
      inputType={InputType.DROPDOWN}
      dropDownValue={watch("priceUnit")}
      onDropDownClick={toggleSelectUnit}
      label="Ваша цена"
      leftText="до"
      placeholder="0"
      subtext={watch("recommendedPrice") && <div className={styles.price_subtext}>
        <span>{watch("recommendedPrice.work")}:</span> <span>{watch("recommendedPrice.price")}</span>
      </div>}
      {...register("price", {valueAsNumber: true, onChange: handleOnChange})}
    />
    <Controller
      control={control}
      name="isAppPaymentWay"
      render={({field}) => {
        return <NewList
          title="Оплачу онлайн"
          subtitle="Самый удобный, безопасный и выгодный способ для оплаты услуг. Подробнее"
          labels={labels}
          onClick={() => setValue("isAppPaymentWay", !field.value)}
          controlIcon={<CheckboxInput
            isNew
            checked={field.value}
            onChange={() => setValue("isAppPaymentWay", !field.value)}
          />}
          hasSubtitleClick
          onSubtitleClick={(event) => {
            event.stopPropagation();
            setOpenPaymentInfo(true);
          }}
        />
      }}
    />

    <InApp open={openSelectUnit} onClose={toggleSelectUnit}>
      <div className={styles.units_holder}>
        {workUnits?.map((item) => {
          return <Controller
            key={item.name}
            control={control}
            name="priceUnit"
            render={({field}) => {
              return <RadioInput
                isNew
                id={`${item.id}`}
                key={`${item.id}`}
                value={item.name}
                label={item.name}
                checked={item.name === field.value}
                onChange={() => setValue("priceUnit", item.name)}
              />
            }}
          />
        })}
      </div>
    </InApp>

    <InApp
      open={openPaymentInfo}
      onClose={() => setOpenPaymentInfo(false)}
      inAppType="image"
      image="/illustrations/payment-with-card-2.svg"
      headerText="Онлайн оплата"
      headerSubtext={headerSubtext}
    >
      <Button text="Выбрать онлайн оплату" hasSpace onClick={handlePaymentsInfoButtonClick}/>
    </InApp>
  </>
}

export default SecondStepOrder;