import api, {BaseResponse, RequestMethod} from "services/api";
import {Nullable}                         from "shared/model";
import {LabelItem}                        from "shared/ui";


export interface WorkUnitItemType {
  id: Nullable<number>;
  name: string;
  is_selected: boolean;
}

export interface AddressItemType {
  address: string;
  longitude: number;
  latitude: number;
  entrance: Nullable<number>;
  apartment: Nullable<number>;
  city: {
    id: number;
    name: string;
  }
}

interface RecommendedOrder {
  recommended_price: Nullable<{
    price?: string;
    work?: string;
  }>;
  work_id: Nullable<number>;
  work_units: WorkUnitItemType[];
}

interface OrderTicketDetails extends RecommendedOrder {
  description: string;
  price_to: Nullable<number>;
  phone: string;
  is_app_payment_way: boolean;
  is_allowed: boolean;
  addresses?: {
    address: string;
    city: { id: number, name: string };
    latitude: string;
    longitude: string;
  }[];
  date?: string;
  is_fast?: boolean;
}

interface OrderDetails {
  city: { id: number, name: string };
  ticket: OrderTicketDetails,
  labels: {
    media?: LabelItem[];
    app_payment?: LabelItem[];
    addresses?: LabelItem[];
  };
}

interface OrderCreateAddressItem extends Omit<AddressItemType, "city" | "apartment" | "entrance"> {
}

interface SaveOrderRequestBody {
  ticket_id?: number;
  work_id?: number;
  is_allowed: boolean;
  description: string;
  addresses?: OrderCreateAddressItem[];
  work_unit_id: Nullable<number>;
  price_to?: number;
  media?: {
    digest: string;
    type: string;
  }[];
  date?: string;
  time?: string;
  is_app_payment_way: boolean;
  is_fast: boolean;
}

export interface OrderRecommendationResponse {
  alert: Nullable<{
    title: string,
    text: string,
  }>;
  ticket: Nullable<RecommendedOrder>;
}

const orderCreateApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchOrderDetails: build.query<OrderDetails | undefined, { ticket_id?: number, work_id?: number }>({
      query:             ({...params}) => ({
        url:    "app/order/service",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<OrderDetails>) => response ? response.content : undefined,
    }),

    fetchOrderRecommendation: build.mutation<OrderRecommendationResponse | undefined, { description: string }>({
      query:             ({...params}) => ({
        url:    "app/order/recommendation",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<OrderRecommendationResponse>) => response ? response.content : undefined,
    }),

    fetchAddressesHistory: build.query<{ addresses: AddressItemType[] } | undefined, void>({
      query:             () => ({
        url:    "app/order/addresses/history",
        method: RequestMethod.PUT,
      }),
      transformResponse: (response: BaseResponse<{
        addresses: AddressItemType[]
      }>) => response ? response.content : undefined,
    }),

    saveOrder: build.mutation<boolean, SaveOrderRequestBody>({
      query:             ({...params}) => ({
        url:    "app/order/save",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
    }),
  }),
});

export const {
  useFetchOrderDetailsQuery,
  useFetchAddressesHistoryQuery,
  useSaveOrderMutation,
  useFetchOrderRecommendationMutation,
} = orderCreateApi;