import React, {ChangeEvent, FormEvent, useEffect, useRef, useState}        from "react";
import {useFieldArray, useForm}                                            from "react-hook-form";
import {useTranslation}                                                    from "react-i18next";
import {useNavigate, useParams}                                            from "react-router-dom";
import {
  NativeActions,
  selectPricesRoute,
  setPricesRoute,
  setProfile,
  setProfileIsCashed,
  SpecialistSubWorkPriceItem,
}                                                                          from "shared/model";
import {useFetchCurrentWorkPricesQuery, useUpdateCurrentWorkPriceMutation} from "shared/api";
import {BaseInput, InApp}                                                  from "shared/ui";
import {Button, Icon, NewButtonColor, NewButtonType}                       from "shared/v12ui";
import {useNativeHandler, useSetHeaders, useSkeletonThrottling}            from "shared/lib";
import {useAppDispatch, useAppSelector}                                    from "store";
import {addNotification, selectLocale}                                     from "store/features";
import {NewPageLayout}                                                     from "entities/general";
import {SkeletonLayout, SkeletonPrices}                                    from "entities/skeleton";
import styles                                                              from "./styles.module.scss";


interface FormData {
  baseValue?: number;
  items?: SpecialistSubWorkPriceItem[];
}

const i18n_prefix = "page.specialist.profile.prices.work."
export default function PricesWork() {
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : null;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const locale = useAppSelector(selectLocale);
  const prevLocation = useAppSelector(selectPricesRoute);

  const {isSuccess} = useSetHeaders();

  const {data, isLoading} = useFetchCurrentWorkPricesQuery(id as number, {
    skip:                      !isSuccess,
    refetchOnMountOrArgChange: true,
  });
  const [updateWorkPrice, {
    isLoading: updateWorkPriceIsLoading,
    isSuccess: updateWorkPriceIsSuccess,
  }] = useUpdateCurrentWorkPriceMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  const [openCancelEditInApp, setOpenCancelEditInApp] = useState(false);
  const [pricesIsOpen, setPricesIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");
  const {control, register, reset, handleSubmit, watch} = useForm<FormData>({
    defaultValues: {
      baseValue: undefined,
      items:     [],
    },
  });

  const {fields} = useFieldArray({
    control,
    name: "items",
  });

  const onBackClick = () => {
    navigate(prevLocation as string);
    dispatch(setPricesRoute(null));
  }

  const handleToggleCancelEditInApp = () => setOpenCancelEditInApp(prev => !prev);

  const handleToggleExpanded = () => {
    setPricesIsOpen(prev => !prev);
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    }
  }
  const handleBackClick = () => {
    const base = watch("baseValue");
    if (!!watch("baseValue") && (base && +base !== data?.price.sum_to)) {
      return handleToggleCancelEditInApp();
    }
    return onBackClick();
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[.,]/g, ""); // Удаляем точки и запятые
    if (sanitizedValue.length > 9) {
      input.value = sanitizedValue.slice(0, 9);
    } else {
      input.value = sanitizedValue;
    }
  }

  const handleOnBeforeInput = (event: FormEvent<HTMLInputElement> & { data: string }) => {
    if (event.data === "." || event.data === "," || event.data === "-") {
      event.preventDefault();
    }
  }

  const onSubmit = (formData: FormData) => {
    if (!id) return;
    const pricesIds = data?.price.prices?.map((item) => ({work_id: item.work_id}));

    const prices = formData.items?.map((item, index) => ({
      ...item,
      sum_to: item.sum_to ? Number(item.sum_to) : null,
      ...(pricesIds ? pricesIds[index] : {}),
    }));

    const price = {
      work_id: id,
      sum_to:  formData.baseValue ? +formData.baseValue : null,
      prices,
    }

    return updateWorkPrice({price})
  };

  const serviceCount = locale === "ru"
    ? t(`${i18n_prefix}service_count.value.keyWithCount`, {count: data?.price.prices?.length})
    : t(`${i18n_prefix}service_count`, {count: data?.price.prices?.length});

  useEffect(() => {
    if (data && data.price && data.price.prices) {
      if (data && data.price.prices.length > 0) {
        reset({items: data.price.prices});
      }
    }
    if (data && data.price) {
      reset({baseValue: data.price.sum_to as number});
    }
  }, [data, reset]);

  useEffect(() => {
    if (updateWorkPriceIsSuccess) {
      onBackClick();
      dispatch(addNotification({
        text: t(`${i18n_prefix}toast`, {service: data?.price.name}),
      }));
      dispatch(setProfile(null));
      dispatch(setProfileIsCashed(false));
    }
  }, [updateWorkPriceIsSuccess]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (openCancelEditInApp) {
      return handleToggleCancelEditInApp();
    }
    return onBackClick();
  });

  const footer = extendedLoading ? <SkeletonLayout height={53} borderRadius={50} theme="dark" /> : <Button
    text={t("common.save")}
    form="hook-form"
    type="submit"
    disabled={updateWorkPriceIsLoading || !watch("baseValue")}
    loading={updateWorkPriceIsLoading}
  />

  return <NewPageLayout
    headerTitle={t(`${i18n_prefix}title`)}
    headerRightIcon={<Icon className="text black" onClick={handleBackClick}>close</Icon>}
    isLoading={extendedLoading}
    footer={footer}
  >
    {extendedLoading
      ? <div className={styles.skeleton_input}>
        <SkeletonLayout width={122} height={17} borderRadius={6} theme="dark" />
        <SkeletonLayout height={42} />
      </div>
      : <form id="hook-form" onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <BaseInput
          autoComplete="off"
          label={data?.price.name}
          icon={<span className={styles.input_prefix}>{t(`${i18n_prefix}to`)}</span>}
          rightIcon={data?.price.unit ? <span className={styles.input_suffix}>{data.price.unit}</span> : undefined}
          type="number"
          inputMode="numeric"
          placeholder="0"
          maxLength={9}
          {...register("baseValue", {valueAsNumber: true, maxLength: 9, onChange: handleOnChange})}
          onBeforeInput={handleOnBeforeInput}
        />

        {extendedLoading
          ? <SkeletonPrices hasDark count={2} />
          : !!data?.price.prices?.length && <div className={styles.prices} onClick={handleToggleExpanded}>
          <div className={styles.prices_info}>
            <span>{t(`${i18n_prefix}details_price`)}</span>
            <span>{serviceCount}</span>
          </div>
          <Icon className={styles.toggle_icon}>{!pricesIsOpen ? "keyboard_arrow_down" : "keyboard_arrow_up"}</Icon>
        </div>}

        <div
          ref={contentRef}
          style={{maxHeight: pricesIsOpen ? contentHeight : "0px"}}
          className={styles.prices_content}
        >
          {fields?.map((item, index) => {
            return <BaseInput
              key={item.id}
              label={item.name}
              icon={<span className={styles.input_prefix}>{t(`${i18n_prefix}to`)}</span>}
              rightIcon={item.unit ? <span className={styles.input_suffix}>{item.unit}</span> : undefined}
              type="number"
              inputMode="numeric"
              placeholder="0"
              maxLength={9}
              {...register(`items.${index}.sum_to`, {
                valueAsNumber: true, maxLength: 9, onChange: handleOnChange,
              })}
              onBeforeInput={handleOnBeforeInput}
              defaultValue={item.sum_to || undefined}
              subtext={item.recommend ? <div className={styles.subtext}>
                <span className="mgr-1-2">{t(`${i18n_prefix}recommend`)}</span>
                <span className="text primary">{item.recommend}</span>
              </div> : undefined}
            />
          })}
        </div>
      </form>}

    <InApp
      inAppType="image"
      image={"/illustrations/no-conversation-3.svg"}
      headerText={t(`${i18n_prefix}cancel_edit.title`)}
      headerSubtext={t(`${i18n_prefix}cancel_edit.description`)}
      open={openCancelEditInApp}
      onClose={handleToggleCancelEditInApp}
    >
      <div className={styles.inApp_buttons}>
        <Button
          type="button"
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={handleToggleCancelEditInApp}
          text={t("common.cancel")}
        />
        <Button
          hasSpace
          type="button"
          buttonColor={NewButtonColor.ERROR}
          onClick={onBackClick}
          text={t("common.close")}
        />
      </div>
    </InApp>
  </NewPageLayout>
};