import React                 from "react";
import {TariffOptionItem}    from "../../../../../services/specialists";
import {CheckboxInput, Tabs} from "shared/ui";
import {SelectedOption}      from "../model/selectedOptionType";
import {NewList}             from "shared/v12ui";
import styles                from "./OptionListItem.module.scss";

function TariffOptionListItem({
  option,
  index,
  currentRef,
  isChecked,
  isDisabled,
  onChange,
  onParentClick,
  onSetOptionItem,
  codeItemIds,
}: {
  option: TariffOptionItem;
  index: number;
  isChecked: boolean;
  isDisabled?: boolean;
  currentRef: React.MutableRefObject<HTMLInputElement[]>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onParentClick: (event: React.MouseEvent<HTMLDivElement>, index: number) => void;
  onSetOptionItem: (code: string, item: number, price: number) => void;
  codeItemIds: SelectedOption[];
}) {

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => onParentClick(event, index);

  const handleSetCodeItem = (item: number, price: number) => onSetOptionItem(option.code, item, price);

  const priceText = !option.items ? option.price_text : (isChecked ? codeItemIds.find((item) => item.code === option.code)?.priceText : option.price_text);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (option.is_disabled) return;
    onChange(event);
  }

  return <>
    <NewList
      title={option.title}
      subtitle={option.subtitle}
      link={{name: priceText}}
      disabled={option.is_disabled}
      onClick={handleOnClick}
      controlIcon={<CheckboxInput
        isNew
        id={option.code}
        ref={(element) => {
          if (element) {
            currentRef.current[index] = element
          }
        }}
        disabled={isDisabled}
        checked={isChecked}
        onChange={handleOnChange}
      />}
      childrenIsOpen={option.items && isChecked}
    >
      <div className={styles.current_tariff_tabs}>
        {option.items?.map((item, index) =>
          <Tabs
            key={index}
            onClick={() => handleSetCodeItem(item.number, item.price)}
            activeTab={codeItemIds.find((codeItem) => codeItem.code === option.code)?.item === item.number}
          >
            {item.title}
          </Tabs>,
        )}
      </div>
    </NewList>
  </>
}

export default React.memo(TariffOptionListItem);