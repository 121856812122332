import React, {useEffect, useRef, useState, VideoHTMLAttributes} from "react";
import {Icon}                                                    from "shared/ui";
import styles                                                    from "./VideoPlayer.module.scss";


const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

// interface Props extends InputHTMLAttributes<HTMLInputElement> {

interface Props extends VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  onSetControlsHeight?: (value?: number) => void;
}

export default function VideoPlayer({src, onSetControlsHeight, ...props}: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [hasPlay, setHasPlay] = useState(false);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      setCurrentTime(currentTime);
      const percentage = (currentTime / videoRef.current.duration) * 100;
      setProgress(percentage);
    }
  };

  const handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (videoRef.current) {
      const progressBar = event.currentTarget as HTMLDivElement;
      const rect = progressBar.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const clickPositionRatio = offsetX / rect.width;
      videoRef.current.currentTime = clickPositionRatio * videoRef.current.duration;
    }
  };

  const handleToggleVideoStatus = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    if (videoRef && videoRef.current) {
      if (!hasPlay) {
        setHasPlay(true);
        videoRef.current.play();
      } else {
        setHasPlay(false);
        videoRef.current.pause();
      }
    }
  }

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        const duration = videoRef.current.duration;
        setVideoDuration(duration);
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      }
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        if (entries[0]) {
          const height = entries[0].contentRect.height;
          onSetControlsHeight && onSetControlsHeight(height + 24);
        }
      });

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return <>
    <video
      ref={videoRef}
      onTimeUpdate={handleTimeUpdate}
      controls={false}
      playsInline={true}
      onLoadedMetadata={props.onLoad}
      onError={props.onError}
      style={props.style}
      poster={`${src}/preview`}
    >
      <source src={src} type="video/mp4" />
    </video>
    <div id="video_player_id" className={styles.controls} ref={ref}>
      <div className={styles.progress_holder}>
        <div className={styles.progress_bar} onClick={handleProgressClick}>
          <div className={styles.progress} style={{width: `${progress}%`}} />
        </div>
        <div className={styles.duration_holder}>
          <div className={styles.duration}>{formatTime(currentTime)}</div>
          <div className={styles.duration}>{formatTime(videoDuration)}</div>
        </div>

      </div>

      {hasPlay ? <Icon iconColor="white" onClick={handleToggleVideoStatus}>pause</Icon>
        : <Icon iconColor="white" onClick={handleToggleVideoStatus}>play</Icon>}
    </div>
  </>
};