import React, {useEffect}                                               from "react";
import {useTranslation}                                                 from "react-i18next";
import {SubmitHandler, useForm}                                         from "react-hook-form";
import {useNavigate}                                                    from "react-router-dom";
import {NewPageLayout, StatePageLayout}                                 from "entities/general";
import {BaseInput, TextBlock, InApp}                                    from "shared/ui";
import {Button, Icon, NewButtonColor, NewButtonType}                    from "shared/v12ui";
import {useCheckSsnFromRemoveProfileMutation, useRemoveProfileMutation} from "shared/api";
import {setActionForNative, useNativeHandler}                           from "shared/lib";
import {NativeActions, setProfile, setProfileIsCashed}                  from "shared/model";
import {useAppDispatch}                                                 from "store";
import styles                                                           from "./RemoveProfile.module.scss";


interface FormState {
  ssn: string,
  hasRemoveOpen: boolean;
  testInAppOpen: boolean;
  ssnValidateLimit: boolean;
}

const i18n_prefix = "page.specialist.profile.remove_profile."
export default function RemoveProfile() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    watch,
    setError,
    setValue,
    clearErrors,
    formState: {errors},
  } = useForm<FormState>({
    defaultValues: {
      ssn:              "",
      hasRemoveOpen:    false,
      testInAppOpen:    false,
      ssnValidateLimit: false,
    },
  });

  const [checkSsn, {data}] = useCheckSsnFromRemoveProfileMutation();
  const [removeProfile, {data: removeProfileResponse}] = useRemoveProfileMutation();

  const handleBackClick = () => {
    return navigate("/profile");
  }

  const handleCancelClick = () => navigate("/profile");

  const handleCloseTestInApp = () => setValue("testInAppOpen", false);

  const handleCloseValidateLimit = () => setValue("ssnValidateLimit", false);

  const handleTestInAppClick = (url: string) => {
    if (url === "naimi://balance/tariffs") {
      return setActionForNative(NativeActions.OPEN_TARIFFS);
    }
    if (url === "naimi://profile/removed") {
      removeProfile();
    }
  }

  const onSubmit: SubmitHandler<FormState> = (data) => checkSsn(data.ssn);

  const onHandleSubmit = () => {
    const data: FormState = {
      ssn:              watch("ssn"),
      hasRemoveOpen:    false,
      testInAppOpen:    false,
      ssnValidateLimit: false,
    };
    onSubmit(data);
  }

  const statePageList = Array.from({length: 3}).map((_, index) =>
    ({
      id:        index,
      icon:      "cancel",
      iconColor: "#FF1507",
      text:      t(`${i18n_prefix}confirm.list.${index}`),
    }),
  );

  useEffect(() => {
    if (!data?.errors && data?.status) {
      setValue("hasRemoveOpen", true);
    }
    if (data?.errors?.profile) {
      setValue("ssnValidateLimit", true);
    }
    if (data?.errors) {
      for (const [fieldName, message] of Object.entries(data.errors)) {
        setError(fieldName as any, {
          type: "server",
          message,
        });
      }
    }
  }, [data]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (watch("hasRemoveOpen")) {
      return setValue("hasRemoveOpen", false);
    }
    if (watch("testInAppOpen")) {
      return setValue("testInAppOpen", false);
    }
    return handleBackClick();
  });

  useEffect(() => {
    if (!removeProfileResponse) return;
    if (removeProfileResponse && removeProfileResponse.content && removeProfileResponse.content.alert) {
      setValue("testInAppOpen", true);
    } else {
      navigate("/profile");
      dispatch(setProfile(null));
      dispatch(setProfileIsCashed(false));
    }
  }, [removeProfileResponse]);

  return <>
    {!watch("hasRemoveOpen")
      ? <NewPageLayout
        headerTitle={t(`${i18n_prefix}title`)}
        headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
        footer={<div className={styles.footer}>
          <TextBlock description={t(`${i18n_prefix}footer_text`)} />

          <Button
            text={t(`${i18n_prefix}buttons.remove`)}
            buttonColor={NewButtonColor.ERROR}
            disabled={watch("ssn").length < 12 || !!errors.ssn}
            onClick={onHandleSubmit}
          />
        </div>}
      >
        <BaseInput
          label={t(`${i18n_prefix}labels.ssn`)}
          placeholder={t(`${i18n_prefix}placeholders.ssn`)}
          error={!!errors.ssn}
          labelIcon="asterix"
          maxLength={12}
          max={12}
          subtext={errors.ssn?.message}
          type="tel"
          {...register("ssn", {
            onChange: () => clearErrors("ssn"),
          })}
        />
      </NewPageLayout>

      : <StatePageLayout
        title={t(`${i18n_prefix}title`)}
        img={"/illustrations/delete-4.svg"}
        list={statePageList}
        onClose={handleCancelClick}
      >
        <div className={styles.confirm_footer}>
          <Button
            text={t(`${i18n_prefix}confirm.cancel`)}
            buttonType={NewButtonType.WRAPPED}
            buttonColor={NewButtonColor.GRAY}
            onClick={handleCancelClick}
          />

          <Button
            text={t(`${i18n_prefix}confirm.remove`)}
            buttonColor={NewButtonColor.ERROR}
            onClick={() => removeProfile()}
          />
        </div>
      </StatePageLayout>}

    <InApp
      inAppType="image"
      image="/illustrations/discount-coupons-2.svg"
      headerText={removeProfileResponse?.content?.alert?.title ?? ""}
      headerSubtext={removeProfileResponse?.content?.alert?.text ?? ""}
      open={watch("testInAppOpen")}
      onClose={handleCloseTestInApp}
    >
      <div className={styles.confirm_footer}>
        {removeProfileResponse?.content?.alert.buttons.map((button, index, array) => {
          if (array.length - 1 === index) {
            return <Button
              key={index}
              hasSpace
              text={button.title}
              onClick={() => handleTestInAppClick(button.link)}
            />
          }
          return <Button
            key={index}
            text={button.title}
            buttonType={NewButtonType.WRAPPED}
            buttonColor={NewButtonColor.GRAY}
            onClick={() => handleTestInAppClick(button.link)}
          />
        })}
      </div>
    </InApp>

    <InApp
      inAppType="image"
      headerText={t(`${i18n_prefix}validate_limit.title`)}
      headerSubtext={t(`${i18n_prefix}validate_limit.description`)}
      image="/illustrations/no-conversation-3.svg"
      open={watch("ssnValidateLimit")}
      onClose={handleCloseValidateLimit}
    >
      <Button hasSpace text={t(`${i18n_prefix}validate_limit.confirm`)} onClick={handleCancelClick} />
    </InApp>
  </>
};