export function NavigationIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_5177_102375)">
      <path
        d="M17.5426 0.780402C17.6758 0.736971 17.8151 0.714844 17.9552 0.714844H17.9656C18.6948 0.714844 19.2858 1.30591 19.2858 2.03503C19.2858 2.19123 19.2581 2.34617 19.2039 2.49269L13.2985 18.4721C13.1177 18.9614 12.6513 19.2863 12.1296 19.2863C11.6393 19.2863 11.1945 18.9987 10.9933 18.5516L8.11606 12.1577C8.05247 12.0164 7.94467 11.8996 7.80891 11.8249L1.3654 8.28097C0.96384 8.06012 0.714355 7.63816 0.714355 7.17987C0.714355 6.63596 1.06429 6.15379 1.58141 5.98516L17.5426 0.780402Z"
        fill="#828291" />
    </g>
    <defs>
      <clipPath id="clip0_5177_102375">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
}