import React                                          from "react";
import {useNavigate}                                  from "react-router-dom";
import {useTranslation}                               from "react-i18next";
import {
  SpecialistProfilePricesDataItem,
  SpecialistProfilePricesItem,
  Nullable,
}                                                     from "shared/model";
import {Tabs}                                         from "shared/ui";
import {Button, NewButtonType, NewButtonColor}        from "shared/v12ui";
import {PriceItem}                                    from "entities/specialist/priceItem";
import {SkeletonPrices, SkeletonTabs, SkeletonLayout} from "entities/skeleton";
import {AppMode}                                      from "store/features";
import {fetchPrices}                                  from "./lib/useFetchSpecialistPrices";
import styles                                         from "./styles.module.scss"


interface Props {
  prices?: SpecialistProfilePricesItem;
  appMode: Nullable<string>;
  id: Nullable<number>;
  isViewMode?: boolean;
  isLoading?: boolean;
  className?: string;
}

const i18n_prefix = "page.specialist.profile.prices.list.";

function PricesBlock({
  prices: propsPrices,
  appMode,
  id: specialistId,
  isViewMode = false,
  isLoading = false,
  className,
}: Props) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {getPrices, pricesFromTabs} = fetchPrices({appMode});

  const [tabId, setTabId] = React.useState<Nullable<number>>(null);
  const [prices, setPrices] = React.useState<SpecialistProfilePricesDataItem[]>([])

  const handleEditClick = () => {
    if (appMode === AppMode.SPECIALIST) {
      return navigate("/profile/prices");
    }
    if (appMode === AppMode.CLIENT) {
      if (tabId) {
        return navigate(`/specialist-prices/${specialistId}/${tabId}`);
      }
      return navigate(`/specialist-prices/${specialistId}`);
    }
  };

  const handleTabClick = (id: Nullable<number>) => {
    if (appMode === AppMode.SPECIALIST && specialistId) return;
    if (isViewMode) return;

    setTabId(id);
    if (appMode === AppMode.SPECIALIST) {
      getPrices({service_id: id})
    } else {
      if (specialistId) {
        getPrices({service_id: id, specialist_id: specialistId});
      }
    }
  }

  const buttonText = () => {
    if (appMode === AppMode.SPECIALIST) {
      if (!prices.length) {
        return t(`${i18n_prefix}button.add`);
      }
      return t("common.edit");
    }
    return t(`${i18n_prefix}button.show_all`);
  }

  React.useEffect(() => {
    if (propsPrices) {
      setPrices(propsPrices.data);
      setTabId(propsPrices.tabs.find((tab) => tab.is_selected)?.id as number)
    }
  }, [propsPrices]);

  React.useEffect(() => {
    if (pricesFromTabs) {
      setPrices(pricesFromTabs.prices);
    }
  }, [pricesFromTabs]);

  return <div className={`${styles.wrapper} ${className}`}>
    {isLoading
      ? <SkeletonLayout borderRadius={6} width={130} height={22} theme="dark" />
      : <span className={styles.header}>
        {t(`${i18n_prefix}header.${appMode}${appMode === AppMode.SPECIALIST && !!specialistId ? "_with_id" : ""}`)}
    </span>}

    {isLoading
      ? <SkeletonTabs />
      : <div className={styles.tabs}>
        {propsPrices?.tabs.map((tab) => <Tabs
          key={tab.id}
          onClick={() => handleTabClick(tab.id)}
          activeTab={tabId === tab.id}>
          {`${tab.text} (${tab.count})`}
        </Tabs>)}
      </div>}

    {isLoading ? <SkeletonPrices /> : prices?.slice(0, 2).map((price) => <PriceItem key={price.id} price={price} />)}

    {!prices.length && <span className={styles.empty}>
      {appMode === AppMode.CLIENT && <>
        {propsPrices?.tabs.length === 1
          ? t(`${i18n_prefix}empty.client.none`)
          : t(`${i18n_prefix}empty.client.with_work`)}
      </>}
      {appMode === AppMode.SPECIALIST && !specialistId && t(`${i18n_prefix}empty.specialist.none`)}
      {appMode === AppMode.SPECIALIST && specialistId && t(`${i18n_prefix}empty.specialist.with_id`)}
    </span>}

    {(appMode === AppMode.SPECIALIST && specialistId) || isViewMode || (propsPrices?.tabs.reduce((acc, item) => acc + item.count, 0) === 0 && appMode === AppMode.CLIENT)
      ? null
      : <>
        {isLoading
          ? <SkeletonLayout height={53} theme="dark" borderRadius={50} />

          : <Button
            text={buttonText()}
            buttonColor={NewButtonColor.GRAY}
            buttonType={NewButtonType.WRAPPED}
            onClick={handleEditClick}
          />
        }
      </>}
  </div>
}

export default React.memo(PricesBlock);