import React, {ButtonHTMLAttributes} from "react";
import styles                        from "./Button.module.scss";
import cn                            from "classnames";
import {NewButtonType}               from "./model/newButtonType";
import Lottie                        from "lottie-react";
import {loaderAnimation}             from "../../../assets/lotties";
import {NewButtonColor}              from "./model/newButtonColor";
import {NewType}                     from "./model/newType";


interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  hasSpace?: boolean;
  viewType?: NewType;
  buttonType?: NewButtonType;
  buttonColor?: NewButtonColor;
  icon?: React.ReactNode;
  additionalIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  additionalText?: string;
  text: string;
  secondLine?: string;
  loading?: boolean;
  onAdditionalButtonClick?: () => void;
}

function Button({
  viewType = NewType.VERTICAL,
  buttonType = NewButtonType.DEFAULT,
  buttonColor = NewButtonColor.BLACK,
  type = "button",
  hasSpace = false,
  icon,
  rightIcon,
  additionalIcon,
  additionalText,
  text,
  secondLine,
  loading,
  onClick,
  onAdditionalButtonClick,
  ...rest
}: Props) {

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;
    if (rest.disabled) return;
    onClick && onClick(event);
  }

  return <div className={cn(styles.wrapper, {[styles.has_space]: hasSpace})}>
    {viewType === NewType.HORIZONTAL && <button
      type="button"
      onClick={onAdditionalButtonClick}
      className={styles.additional_button}
    >
      {additionalIcon
        ? <div className={styles.additional_icon}>{additionalIcon}</div>
        : <div className={styles.additional_text}>{additionalText}</div>}
    </button>}
    <button
      type={type}
      onClick={handleButtonClick}
      className={cn(styles.button,
        {[`${styles[buttonType]}`]: buttonType},
        {[`${styles[buttonColor]}`]: buttonColor},
        {[styles.is_loading]: loading},
      )}
      {...rest}
    >
      {loading && <div className={styles.loading_wrapper}>
        <Lottie
          autoPlay
          animationData={loaderAnimation}
          loop
          className={cn(
            styles.loading,
            {[`${styles[buttonType]}`]: buttonType},
            {[`${styles[buttonColor]}`]: buttonColor},
          )}
        />
      </div>}
      <div className={cn(styles.children_holder, {[styles.is_loading]: loading})}>
        <div>{icon}</div>
        <div className={cn(styles.text_wrapper, {[styles.second_line]: !!secondLine})}>
          <span>{text}</span>
          {secondLine && <span>{secondLine}</span>}
        </div>
        <div>{rightIcon}</div>
      </div>
    </button>
  </div>
}

export default React.memo(Button);