export type Nullable<T> = T | null;

export enum ImageResizeMode {
  RESIZE = "r",
  CROP = "c"
}

export const enum NativeActions {
  ANALYTICS = "analytics",
  BACK_TAP = "back",
  TERMINATE = "terminate",
  LOGOUT = "logout",
  MAKE_DOC_PHOTO = "makeDocPhoto",
  MAKE_FACE_PHOTO = "makeFacePhoto",
  OPEN_GID = "openGid",
  OPEN_IN_APP_BROWSER = "openInAppBrowser",
  OPEN_BIOMETRIC = "openBiometricsLiveness",
  OPEN_ONBOARDING = "openOnboarding",
  OPEN_TICKETS = "openTickets",
  OPEN_TERMS = "openTerms",
  OPEN_UPLOAD_LOADER = "openUploadLoader",
  OPEN_UPLOAD_ERROR = "openUploadError",
  OPEN_PAYMENTS = "openPayments",
  OPEN_FF_CARD = "openFFCard",
  OPEN_NEWSLINE_FILTERS = "openNewslineFilters",
  OPEN_NEWSLINE = "openNewsline",
  OPEN_CARDS = "openCards",
  OPEN_SUPPORT = "openSupport",
  OPEN_SHARE = "openShare",
  OPEN_PORTFOLIO_UPLOAD = "openPortfolioUpload",
  OPEN_AVATAR_UPLOAD = "openAvatarUpload",
  AVATAR_UPLOAD_STARTED = "avatarUploadStarted",
  AVATAR_UPLOAD_FINISHED = "avatarUploadFinished",
  AVATAR_UPLOAD_FAILED = "avatarUploadFailed",
  CALL_TO_SPECIALIST = "callToSpecialist",
  WRITE_TO_SPECIALIST = "writeToSpecialist",
  WRITE_LOADER_OPEN = "writeLoaderOpen",
  WRITE_LOADER_CLOSE = "writeLoaderClose",
  OPEN_VACANCY_CREATION = "openVacancyCreation",
  OPEN_REGISTRATION = "openRegistration",
  OPEN_TARIFFS = "openTariffs",
  HAS_PERMISSIONS_GEO = "permissionsGeoHas",
  HAS_PERMISSIONS_GALLERY = "permissionsGalleryHas",
  HAS_PERMISSIONS_CAMERA = "permissionsCameraHas",
  GIVE_CAMERA_PERMISSION = "permissionsCameraGive",
  GIVE_GALLERY_PERMISSION = "permissionsGalleryGive",
  GIVE_GEO_PERMISSION = "permissionsGeoGive",
  OPEN_BACK_CAMERA = "openBackCamera",
  OPEN_GALLERY = "openGallery",
  GET_GEO_COORDS = "getGeoCoords",
  MEDIA_TRANSFER = "mediaTransfer",
  MEDIA_PROGRESS = "mediaProgress",
  MEDIA_CLEAN = "mediaClean",
  MEDIA_FAILED = "mediaFailed",
  MEDIA_RETRY = "mediaRetry",
}