import * as React                                             from "react";
import {useNavigate}                                          from "react-router-dom";
import {Tabs, TabVariant}                                     from "shared/ui/Tabs";
import {setActionForNative}                                   from "shared/lib";
import {useAppDispatch, useAppSelector}                       from "store";
import {selectFreezeWaiting, setFreezeWaiting, setTariffDays} from "store/features";
import {NativeActions, ButtonRenderType}                      from "shared/model";
import {buttonRender}                                         from "./lib";
import styles                                                 from "./styles.module.scss";
import cn                                                     from "classnames";


type CardType = "tariff" | "credit";

interface Props {
  type: CardType;
  title: string;
  subtitle?: string;
  description?: string;
  buttons?: string[];
  color?: string;
  isLoading?: boolean;
  onClick?: (id: number, type: ButtonRenderType) => void;
  classNames?: string;
}

function RenderTab({button, type, isLoading, onClick}: {
  type: CardType,
  isLoading?: boolean;
  button: string,
  onClick?: (id: number, type: ButtonRenderType) => void
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const icon = buttonRender(button).icon;
  const text = buttonRender(button).text
  const buttonType = buttonRender(button).type;
  const id = buttonRender(button).id;
  const days = buttonRender(button).days;
  const currentId = id ? +id : null;
  const url = buttonRender(button).partnerUrl;

  const handleTariffUnfreeze = () => {
    if (!currentId) return;
    onClick && onClick(currentId, ButtonRenderType.TARIFF_UNFREEZE);
    dispatch(setTariffDays(days));
    dispatch(setFreezeWaiting(false));
  }

  const handleOpenPartnerSite = () => setActionForNative(NativeActions.OPEN_IN_APP_BROWSER, {url});

  const handleOnClick = () => {
    if (isLoading) return;
    switch (type) {
      case "tariff":
        switch (buttonType) {
          case ButtonRenderType.TARIFF_EDIT:
            return navigate(`options/${currentId}`);
          case ButtonRenderType.TARIFF_CREATE:
            return navigate("periods");
          case ButtonRenderType.TARIFF_FREEZE:
            if (currentId) {
              return onClick && onClick(currentId, ButtonRenderType.TARIFF_FREEZE);
            }
            return null
          case ButtonRenderType.TARIFF_UNFREEZE:
            if (currentId) {
              return handleTariffUnfreeze();
            }
            return null
          case ButtonRenderType.TARIFF_SUBSCRIBE_ERROR:
            if (currentId) {
              return onClick && onClick(currentId, ButtonRenderType.TARIFF_SUBSCRIBE_ERROR);
            }
            return null
          default:
            return;
        }
      case "credit":
        switch (buttonType) {
          case ButtonRenderType.CREDIT_SCHEDULE:
            return navigate(`schedule/${currentId}`);
          case ButtonRenderType.CREDIT_PREPAYMENT:
            if (currentId) {
              return onClick && onClick(currentId, ButtonRenderType.CREDIT_PREPAYMENT);
            }
            return null
          case ButtonRenderType.CREDIT_ERROR:
            if (currentId) {
              return onClick && onClick(currentId, ButtonRenderType.CREDIT_ERROR);
            }
            return null
          case ButtonRenderType.OPEN_PARTNER_SITE:
            return handleOpenPartnerSite();
        }
    }
  }

  return <Tabs
    key={button}
    variant={TabVariant.SECONDARY}
    onClick={handleOnClick}
    activeTab={false}
    icon={icon}
  >
    {text}
  </Tabs>
}

const DEFAULT_TARIFF_BG_COLOR = "#F7F7F9";
const SpecialistPaymentInformationCard = ({
  type,
  title,
  subtitle,
  description,
  buttons,
  color,
  isLoading,
  onClick,
  classNames,
}: Props) => {
  const freezeState = useAppSelector(selectFreezeWaiting);

  return <div
    className={cn(styles.current_tariff_wrapper, classNames)}
    style={{backgroundColor: color ?? DEFAULT_TARIFF_BG_COLOR}}
  >
    <div className={styles.current_tariff_header}>
      <div>{title}</div>
      <div>{subtitle}</div>
    </div>

    <div className={styles.current_tariff_description}>
      {description}
    </div>

    {!!buttons?.length && <div className={styles.current_tariff_tabs}>
      {buttons?.map((button) => {
        if (freezeState && buttonRender(button).type === ButtonRenderType.TARIFF_FREEZE) {
          return null
        }
        return <RenderTab
          key={button}
          button={button}
          type={type}
          isLoading={isLoading}
          onClick={onClick}
        />
      })}
    </div>}
  </div>
};

export default SpecialistPaymentInformationCard;