import React                                                                   from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
}                                                                              from "react-hook-form";
import {CreateState}                                                           from "../model/createState";
import {Button, Input, InputType, NewButtonColor, NewButtonType, Tabs}         from "shared/v12ui";
import {AddressesItem, YaMap}                                                  from "widgets/yaMap";
import {Icon, InApp, InputPhone, LabelItem}                                    from "shared/ui";
import styles                                                                  from "./Steps.module.scss";
import {NativeActions}                                                         from "shared/model";
import {useNativeHandler}                                                      from "shared/lib";
import {formatAddressName, yaMapSearch}                                        from "widgets/yaMap/model/yaMapHelpers";
import {useAppDispatch, useAppSelector}                                        from "store";
import {selectCurrentCity, selectIsDemo}                                       from "store/features";
import {selectGeolocationPermission, setGeolocationPermission, toggleGeoInApp} from "features/permissions";
import {GeolocationRequest}                                                    from "features/permissions/geolocation";


interface Props {
  register: UseFormRegister<CreateState>;
  watch: UseFormWatch<CreateState>;
  control: Control<CreateState>;
  setValue: UseFormSetValue<CreateState>;
  fields: FieldArrayWithId<CreateState, "addresses">[];
  remove: UseFieldArrayRemove;
  labels?: LabelItem[];
}

const mockList = [
  {id: 0, text: "Напишите в чат", icon: "chat"},
  {id: 1, text: "Позвоните мне", icon: "phone"},
];

function ThirdSteOrder({register, watch, control, setValue, fields, remove, labels}: Props) {
  const dispatch = useAppDispatch();
  const currentCity = useAppSelector(selectCurrentCity);
  const isDemoClient = useAppSelector(selectIsDemo);
  const hasGeoPermission = useAppSelector(selectGeolocationPermission);

  const [openMap, setOpenMap] = React.useState(false);
  const [openInApp, setOpenInApp] = React.useState(false);
  const [addressName, setAddressName] = React.useState<string>();
  const [currentGeo, setCurrentGeo] = React.useState<number[]>([]);

  const handleSetAddress = (item: AddressesItem) => {
    setValue("addresses", [...watch("addresses"), item]);
  }

  const handleToggleMap = () => setOpenMap(prev => !prev);

  const handleGiveCurrentLocationAddress = () => {
    if (!currentGeo) return;
    yaMapSearch({text: `${currentGeo}`, limit: 1})
      .then((res) => {
        const addresses = [{
          coord:   res[0].geometry?.coordinates as number[],
          name:    formatAddressName(res[0].properties.name) as string,
          city_id: currentCity?.id,
        }]
        setValue("addresses", addresses);
      }).catch((_) => () => {
    });
  }

  const onInputClick = () => {
    if (!watch("addresses").length) {
      if (hasGeoPermission === false) {
        return dispatch(toggleGeoInApp(true));
      }
      if (hasGeoPermission === true) {
        handleGiveCurrentLocationAddress();
      }
    }
    return setOpenInApp(true);
  }

  const handleADdMoreClick = () => {
    setOpenInApp(false);
    setOpenMap(true);
    setAddressName(undefined);
  }

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.GIVE_GEO_PERMISSION, (data) => {
    dispatch(setGeolocationPermission(data.has_permissions));
  });

  return <>
    <Input
      inputType={InputType.MAP}
      label="Адрес"
      icon="place"
      value={watch("addresses")?.map((item) => item.name).join("→") ?? ""}
      readOnly
      placeholder="г. Астана, адрес не указан"
      labels={labels}
      onClick={onInputClick}
      subtext="Рекомендуем указать адрес, чтобы специалисты могли понять маршрут и время на дорогу"
      rightIcon={!!watch("addresses").length ?
        <Icon size={20} iconColor="#828291" className="mgl-3" isNew>add</Icon> : undefined}
    />

    <Controller
      control={control}
      name={"isAllowed"}
      render={({field}) => {
        return <Tabs
          header="Как с вами связаться?"
          isFullPosition
          list={mockList}
          currentTab={field.value ? 1 : 0}
          onSetCurrentTab={(value) => setValue("isAllowed", value === 1)}
        />
      }}
    />

    {watch("isAllowed") ? <div className={styles.phone_holder}>
        <InputPhone
          icon={<div className={styles.phone_prefix + " mgr-2"}>
            <img src={"images/country_kz_2.svg"} className={styles.phone_prefix_icon} alt="" />
            <div>{"+7"}</div>
          </div>}
          placeholder="000 000-00-00"
          mask={"(000) 000-00-00"}
          disabled={!isDemoClient}
          type="tel"
          // disabled
          {...register("phone", {
            // required:  true,
            // minLength: methodType === RegistrationMethodType.VERIFICATION ? undefined : 13,
          })}
        />
      </div>
      : <div className={styles.subtext}>
        Рекомендуем указать выбрать способ связи “Написать в чате”, это безопаснее
      </div>}

    {openMap && <YaMap
      currentCity={currentCity}
      addresses={watch("addresses")}
      onClose={handleToggleMap}
      onSetAddress={handleSetAddress}
      addressName={addressName}
    />}

    <InApp open={openInApp} onClose={() => setOpenInApp(false)}>
      <div className={styles.inApp_buttons}>
        {!!fields.length && <ul className={styles.addresses_holder}>
          {fields.map((_, index) => {
            return <li key={index}>
              <Controller
                control={control}
                name={`addresses.${index}.name`}
                render={({field}) => <Input
                  readOnly
                  hasSpace={false}
                  value={field.value}
                  onClick={() => {
                    setAddressName(field.value);
                    setOpenInApp(false);
                    setOpenMap(true);
                  }}
                  icon={<div className={styles.input_count}>{index + 1}</div>}
                  rightIcon={
                    <Icon
                      isNew
                      size={20}
                      iconColor="#FF1507"
                      className="mgl-3"
                      onClick={() => remove(index)}
                    >
                      delete
                    </Icon>
                  }
                />}
              />
            </li>
          })}
        </ul>}

        {watch("addresses").length < 5 && <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          text="Добавить еще"
          onClick={handleADdMoreClick}
          rightIcon={<Icon size={20} iconColor="#2B2B2B" isNew>add</Icon>}
          hasSpace
        />}
      </div>
    </InApp>

    <GeolocationRequest setCurrentGeo={setCurrentGeo} onClose={handleToggleMap} />
  </>
}

export default ThirdSteOrder;