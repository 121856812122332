import React from "react";
import {LabelItem, Textarea} from "shared/ui";
import {Tabs} from "shared/v12ui";
import {
  UploadMedia,
} from "features/global/uploadMedia";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {CreateState} from "../model/createState";
import {
  PickOrderDate,
} from "features/order/pickDate";
import {DateListType} from "../model/dateListType";


interface Props {
  watch: UseFormWatch<CreateState>;
  errors: FieldErrors<CreateState>;
  register: UseFormRegister<CreateState>;
  labels?: LabelItem[];
  control: Control<CreateState>;
  setValue: UseFormSetValue<CreateState>;
  dateList: DateListType[];
  clearErrors: UseFormClearErrors<CreateState>;
  onSetDateList: React.Dispatch<React.SetStateAction<DateListType[]>>;
}

function FirstStepOrder({
  watch,
  errors,
  dateList,
  control,
  register,
  labels,
  setValue,
  clearErrors,
  onSetDateList,
}: Props) {
  const handleSetDateValue = (value?: string | number) => {
    if (!value) return;
    if (value === "selectDate") {
      setValue("openSelectTime", true);
    }
    setValue("datePick", value as string);
  }

  const handleCloseSelectTime = (hasSave?: boolean) => {
    if (!hasSave) {
      setValue("datePick", undefined);
    }
    setValue("openSelectTime", false);
  };

  const onUpdateDateAndTime = (date?: string, time?: string) => {
    setValue("date", date);
    setValue("time", time);
  }

  return <>
    <Textarea
      isNew
      isRequired
      hasSpace
      errorMessage={errors.description?.message}
      hasSymbolCounter={false}
      placeholder="Что нужно сделать?"
      label="Описание"
      hint="Указав описание подробнее, вам не потребуется уточнять информацию каждому специалисту."
      {...register("description", {
        onChange: () => {
          clearErrors("description")
        },
      })}
    />

    <UploadMedia labels={labels} />

    <Controller
      control={control}
      name="datePick"
      render={({field}) => {
        return <Tabs
          header="Когда нужна услуга"
          list={dateList}
          currentTab={field.value}
          onSetCurrentTab={handleSetDateValue}
        />
      }}
    />

    <PickOrderDate
      open={watch("openSelectTime")}
      onClose={handleCloseSelectTime}
      onUpdateDateList={onSetDateList}
      onUpdateDateAndTime={onUpdateDateAndTime}
    />
  </>
}

export default FirstStepOrder;