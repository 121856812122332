import React, {useEffect, useState}                  from "react";
import {useTranslation}                              from "react-i18next";
import {InApp}                                       from "shared/ui";
import {Button, Icon, NewButtonColor, NewButtonType} from "shared/v12ui";
import {useRemoveCurrentWorkPriceMutation}           from "shared/api";
import {setProfile, setProfileIsCashed}              from "shared/model";
import {useAppDispatch}                              from "store";
import {addNotification}                             from "store/features";
import styles                                        from "./styles.module.scss";


interface Props {
  id: number;
}

const i18n_prefix = "page.specialist.profile.prices.service."
export default function RemoveWorkPrice({id}: Props) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [removeWorkPrice, {isLoading, isSuccess}] = useRemoveCurrentWorkPriceMutation();

  const [inAppOpen, setInAppOpen] = useState(false);

  const handleToggleInApp = () => setInAppOpen(prev => !prev);

  const handleRemoveWorkPrice = () => removeWorkPrice(id);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addNotification({text: t(`${i18n_prefix}remove.toast`)}));
      dispatch(setProfile(null));
      dispatch(setProfileIsCashed(false));
      handleToggleInApp();
    }
  }, [isSuccess]);

  return <>
    <Icon onClick={handleToggleInApp}>delete</Icon>

    <InApp
      inAppType="image"
      open={inAppOpen}
      image={"/illustrations/delete-4.svg"}
      headerText={t(`${i18n_prefix}remove.title`)}
      headerSubtext={t(`${i18n_prefix}remove.description`)}
      onClose={handleToggleInApp}
    >
      <div className={styles.button_wrapper}>
        <Button
          text={t("common.cancel")}
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={handleToggleInApp}
        />

        <Button
          hasSpace
          disabled={isLoading}
          loading={isLoading}
          text={t(`${i18n_prefix}remove.confirm`)}
          buttonColor={NewButtonColor.ERROR}
          onClick={handleRemoveWorkPrice}
        />
      </div>
    </InApp>
  </>
};