export const order_create = {
  header:        {
    title:    "Заявка на услугу",
    subtitle: [
      "Шаг 1 из 3 — описание",
      "Шаг 2 из 3 — оплата",
      "Шаг 3 из 3 — контакты",
    ],
  },
  create_button: "Создать заявку",
}