import * as React                              from "react";
import {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
}                                              from "react";
import {useNavigate, useParams}                from "react-router-dom";
import {useTranslation}                        from "react-i18next";
import {
  useEditCurrentTariffMutation,
  useLazyGetTariffOptionsQuery,
  useSetCartInitMutation,
}                                              from "services/specialists/tariffs";
import {
  Tooltip,
  Toggle,
  List,
}                                              from "shared/ui";
import {Button, Icon}                          from "shared/v12ui";
import {
  useNativeHandler,
  setActionForNative,
  formatNumberWithSpaces,
  useCheckPaymentStatus,
  useOpenPayments,
  onParentClick,
}                                              from "shared/lib";
import {NativeActions}                         from "shared/model";
import {LoadingScreen, NewPageLayout}          from "entities/general";
import {SkeletonLayout}                        from "entities/skeleton";
import {useAppSelector}                        from "store";
import {PurchaseType, selectPurchaseIsLoading} from "store/features";
import styles                                  from "./styles.module.scss";
import {AutoRenewalInfo}                       from "pages/StatePages/AutoRenewalInfo";
import {SelectedOption}                        from "./model/selectedOptionType";
import TariffOptionListItem                    from "./ui/OptionListItem";


const i18n_prefix = "page.specialist.tariff_options."
const TariffOptionsPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {period: paramPeriod, is_subscribe: paramIsSubscribe} = useParams();
  const period = paramPeriod ? parseInt(paramPeriod) : undefined;
  const isSubscribe = paramIsSubscribe ? paramIsSubscribe : null;
  const checkboxListRefs = useRef<HTMLInputElement[]>([]);
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);


  const [fetchTariffOptions, {data: tariffOptions, isLoading: tariffOptionsIsLoading}] = useLazyGetTariffOptionsQuery();
  const [setCartInit, {
    data:      tariffCartInitResponse,
    isLoading: tariffCartInitIsLoading,
  }] = useSetCartInitMutation();
  const [editTariff, {
    data:      editTariffResponse,
    isLoading: editTariffIsLoading,
    isSuccess: editTariffIsSuccess,
  }] = useEditCurrentTariffMutation();

  const [price, setPrice] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [initialEditOptions, setInitialEditOptions] = useState<string[]>([]);
  const [subscribeStatus, setSubscribeStatus] = useState(false);
  const [autoRenewalInfoIsOpen, setAutoRenewalInfoIsOpen] = useState(false);
  const [modalHasOpened, setModalHasOpened] = useState(false);

  const handleToggleAutoRenewalInfo = () => setAutoRenewalInfoIsOpen(prev => !prev);

  const handleOnLinkClick = () => {
    handleToggleAutoRenewalInfo();
    setModalHasOpened(true);
  }

  const handleBackClick = () => {
    if (isSubscribe === null) {
      return navigate("/tariffs");
    }
    return navigate("/tariffs/periods");
  };

  const handleOptionSelectChange = ({target: {id: code, checked}}: ChangeEvent<HTMLInputElement>) => {
    const currentOption = tariffOptions?.content.options.find((option) => option.code === code);
    const checkedOptionItem = currentOption?.items?.find((optionItem) => optionItem.is_checked);
    const newOption = {
      code:      currentOption!.code,
      price:     currentOption!.price,
      item:      checkedOptionItem?.number,
      itemPrice: checkedOptionItem?.price,
      priceText: checkedOptionItem?.price_text,
    };

    setSelectedOptions((prevOptions) =>
      checked ? [...prevOptions, newOption] : prevOptions.filter((option) => option.code !== code));
  }

  const handleSetOptionItem = (code: string, item: number, price: number) => {
    setSelectedOptions(prev => prev.map((codeItem) =>
      codeItem.code === code ? {...codeItem, item, itemPrice: price} : codeItem));
  }

  const handleParentClick = (event: MouseEvent<HTMLDivElement>, index: number) => onParentClick(event, index, checkboxListRefs);

  const handleEditTariff = () => {
    const allOptions = tariffOptions?.content.options.map((option) => option.code);

    const resultArray = allOptions?.map((option) => ({
      code:       option,
      is_checked: selectedOptions.some((item) => item.code === option),
      number:     selectedOptions.find((item) => item.code === option)?.item,
    }));

    if (period) {
      editTariff({id: period, options: resultArray});
    }
  }

  const handleFormSubmit = () => {
    const options = selectedOptions
      .map(option => option.item ? {code: option.code, number: option.item} : {code: option.code});

    if (period) {
      if (isSubscribe === null) {
        return handleEditTariff();
      }
      return setCartInit({is_subscribe: subscribeStatus, days: period, options});
    }
  }

  useEffect(() => {
    if (!period) return;
    if (!isSubscribe) {
      fetchTariffOptions({id: period});
    }
    if (typeof isSubscribe === "string") {
      const hasSubscribe = isSubscribe === "true";
      fetchTariffOptions({days: period, is_subscribe: hasSubscribe});
    }

  }, [period, isSubscribe, fetchTariffOptions]);

  useEffect(() => {
    if (!tariffOptions?.content.options) return;
    const checkedOptions = tariffOptions.content.options.filter((option) => option.is_checked);
    const currentOptions = checkedOptions.map((option) => ({
      code:      option.code,
      price:     option.price,
      item:      option.items?.find((item => item.is_checked))?.number,
      itemPrice: option.items?.find((item => item.is_checked))?.price,
      priceText: option.items?.find((item => item.is_checked))?.price_text,
    }));

    if (isSubscribe === null) {
      const initialIds = checkedOptions.map((option) => option.code);
      setInitialEditOptions(initialIds);
    }

    setSelectedOptions(currentOptions ?? []);
  }, [tariffOptions, isSubscribe]);

  useEffect(() => {
    if (!selectedOptions) return;
    const price = selectedOptions.reduce((sum, option) => {
      const itemPrice = option.itemPrice ?? 0;
      const priceWithItems = option.item ? 0 : option.price;
      return sum + priceWithItems + itemPrice;
    }, 0);

    setPrice(price);
  }, [selectedOptions]);

  useEffect(() => {
    if (editTariffIsSuccess && editTariffResponse && !editTariffResponse?.content?.payment_id) {
      return navigate("/tariffs");
    }
  }, [editTariffIsSuccess, editTariffResponse, navigate]);

  useOpenPayments({
    paymentId: tariffCartInitResponse?.content?.payment_id ?? editTariffResponse?.content?.payment_id,
    type:      PurchaseType.TARIFF_INIT,
  });

  useEffect(() => {
    if (modalHasOpened) return;
    if (tariffOptions && tariffOptions.content && tariffOptions.content.subscribe) {
      const hasSubscribe = tariffOptions.content.subscribe.is_checked;
      setSubscribeStatus(hasSubscribe);
    }
  }, [tariffOptions, modalHasOpened]);

  const hasItemPrice = selectedOptions
    .filter((option) => option.item !== undefined)
    .reduce((acc, item) => {
      const currentItemPrice = item.itemPrice ?? 0;
      return acc + currentItemPrice
    }, 0) === 0;

  useEffect(() => {
    const action = isSubscribe === null ? "edit" : "purchase";
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "specialist tariff selection opened",
      properties: {
        "entry point": "naimi://purchase",
        "action":      action,
      },
    });
  }, [isSubscribe]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (autoRenewalInfoIsOpen) return;
    if (purchaseInProgress) return;
    if (isSubscribe === null) {
      return navigate("/tariffs");
    }
    return navigate("/tariffs/periods");
  });

  const editButtonIsDisabled = selectedOptions.length === initialEditOptions.length &&
    selectedOptions.every(option => initialEditOptions.includes(option.code)) && hasItemPrice;

  const returnToTariffsAfterPayments = () => navigate("/tariffs");

  const {paymentStatusLoading} = useCheckPaymentStatus({refetch: returnToTariffsAfterPayments});

  const footer = <div className={styles.footer}>
    {tariffOptions?.content?.subscribe && <Tooltip
      hasShow={!!tariffOptions?.content?.subscribe.is_disabled}
      icon="info"
      text={t(`${i18n_prefix}auto_renewal_prompt`)}
    >
      <List
        title={tariffOptions?.content?.subscribe.title}
        subtitle={tariffOptions?.content?.subscribe?.subtitle}
        disabled={tariffOptions?.content?.subscribe.is_disabled}
        labels={tariffOptions?.content?.subscribe?.labels}
        link={{name: t("common.how_it_works")}}
        onLinkClick={handleOnLinkClick}
        controlIcon={<Toggle
          disabled={tariffOptions.content.subscribe.is_disabled}
          checked={subscribeStatus}
          onChange={(event) => setSubscribeStatus(event.target.checked)}
        />}
      />
    </Tooltip>}

    <Button
      text={price === 0 || !price ? t(`${i18n_prefix}buttons.save`) : t(`${i18n_prefix}buttons.pay`)}
      secondLine={price !== 0 ? t(`${i18n_prefix}buttons.price`, {price: `${formatNumberWithSpaces(price)}`}) : undefined}
      onClick={handleFormSubmit}
      disabled={tariffOptionsIsLoading || !tariffOptions || editButtonIsDisabled}
      loading={tariffCartInitIsLoading || editTariffIsLoading}
    />
  </div>

  return <>
    {paymentStatusLoading && <LoadingScreen />}

    {autoRenewalInfoIsOpen && <AutoRenewalInfo isOpen={autoRenewalInfoIsOpen} onClose={handleToggleAutoRenewalInfo} />}

    <NewPageLayout
      isLoading={tariffOptionsIsLoading}
      headerTitle={tariffOptions?.content?.title}
      headerSubtitle={tariffOptions?.content?.subtitle}
      headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
      footer={footer}
    >
      <>
        {tariffOptionsIsLoading
          ? <>{[...Array(3)].map((_, index) => <SkeletonLayout key={index} height={86} />)}</>

          : tariffOptions?.content?.options?.map((option, index) =>
            <TariffOptionListItem
              key={index}
              currentRef={checkboxListRefs}
              index={index}
              option={option}
              isDisabled={option.is_disabled}
              isChecked={selectedOptions.some((currentOption) => currentOption.code === option.code)}
              onChange={handleOptionSelectChange}
              onParentClick={handleParentClick}
              onSetOptionItem={handleSetOptionItem}
              codeItemIds={selectedOptions}
            />)}
      </>
    </NewPageLayout>
  </>
}

export default TariffOptionsPage;