export const profile = {
  more:              {
    opt_1:      "Клиент менің анкетамды қалай көреді?",
    opt_2:      "Бөлісу",
    opt_3:      "Анкетаны жою",
    open_share: "Сәлем! Жұмысқа жалдауға болатын жақсы маманның анкетасымен бөліскім келеді. Міне оның анкетасына сілтеме: {{link}}. Пайдалы болады деп үміттенемін!",
  },
  about:             {
    title:         "Мен жайлы",
    on_moderation: "Модерацияда",
    empty:         {
      client:     "Мұнда маман “Мен жайлы” деген жерде өзі туралы көрсеткен ақапарт болады ",
      specialist: "Мұнда сіз өзіңіз туралы көрсеткен ақпарат болады",
    },
    button:        {
      add:    "Ақпаратты қосу",
      update: "Өзгерту",
      save:   "Сақтау",
    },
    inApp:         {
      title:       "Сенімдісіз бе?",
      description: "Егер бетті жапсаңыз, сіз толтырған ақпараттар сақталмайды",
      button:      {
        cancel: "Бас тарту",
        close:  "Жабу",
      },
    },
    toast:         "Өзгертулер сақталды",
    placeholder:   "Мәтінді осында енгізіңіз",
    hint:          "Клиентте сіздің жұмысыңыз туралы түсінік болуы үшін, өзіңіз жайлы қысқаша айтып кетіңіз",
    error:         "Бұл жерге 300-ден артық символ сыймайды, мәтінді қысқартыңыз",
  },
  reviews:           {
    title:  "Пікірлер",
    button: "Барлығын қарау",
    empty:  {
      client:     {
        none:      "Бұл анкетада әлі клиенттерден пікірлер жоқ. Маманмен байланысып көріңіз. Сіздің пікіріңіз үшін үлкен жеңілдік ұсынуы немесе сіз ұсынған бағаға келісуі мүмкін",
        with_work: "Маманда бұл қызмет түрі бойынша әлі пікірлер жоқ. Алайда, сіз осы маманның басқа қызмет түрлері бойынша алған пікірлерімен таныса аласыз",
      },
      specialist: {
        none: "Сізде әлі пікірлер жоқ. Клиентке тиімді бағаны ұсыныр немесе сізді naimi.kz  арқылы жалдауын және бағалауын сұрап алғашқы пікірлерді алыңыз",
      },
    },
  },
  prices:            {
    list:    {
      header: {
        specialist:         "Менің қызметтерім",
        client:             "Маманның қызметтері",
        specialist_with_id: "Маманның қызметтері",
      },
      empty:  {
        client:     {
          with_work: "Маманда бұл қызмет үшін бағалар әлі көрсетілмеген",
          none:      "Бұл жерде осы маманның көрсететін қызметтері болады",
        },
        specialist: {
          none:    "Бұл жерде сіз өтінімдер тізіміне қосқан қызметтер болады",
          with_id: "Бұл жерде осы маманның көрсететін қызметтері болады",
        },
      },
      title:  "Маманның қызметтері",
      button: {
        show_all: "Барлығын қарау",
        add:      "Қызметтерді қосу",
      },
    },
    update:  {
      title:            "Қызметтердің бағалары",
      subtitle:         "Өтінімдер тізімінен сіздің санаттарыңыз",
      service_subtitle: "{{count_to}} ішінен {{count_from}} қосылды",
      empty_prices:     {
        title:    "Санатты таңдаңыз",
        subtitle: "Қызметтерге бағаны көрсету үшін, сізді қызықтыратын қызметтер санатын таңдаңыз",
        button:   "Таңдау",
      },
    },
    service: {
      subtitle:           "{{count_to}} ішінен {{count_from}} қосылды",
      search_placeholder: "Іздеу",
      empty_price:        "Баға көрсетілмеген",
      price:              "Дейін {{price_to}} / {{unit}}",
      link:               "Өзгерту",
      remove:             {
        title:       "Қызметті жою?",
        description: "Қызмет жойылады, және де сіз көрсетілген ақпарат жоғалады",
        confirm:     "Жою",
        toast:       "Қызмет тізімнен жойылды",
      },
      empty_search:       {
        title:       "Ештеңе табылмады",
        description: "Бұл сұрау бойынша қызмет табылмады",
      },
    },
    work:    {
      title:         "Қызметті қосу",
      recommend:     "кеңес беретін баға:",
      details_price: "Толығырақ қызмет бағасын білу",
      service_count: "{{count}} қызмет",
      to:            "дейін",
      cancel_edit:   {
        title:       "Сенімдісіз бе?",
        description: "Егер бетті жапсаңыз, сіз толтырған ақпараттар сақталмайды",
      },
      toast:         "{{service}} қызметі қосылды",
    },
  },
  remove_profile:    {
    title:          "Анкетаны жою",
    footer_text:    "Анкетаны қалпына келтіру 3000тг болады. Ал жаңа анкетаны толтыру мүмкін емес, өйткені бір ЖСНмен тек бір ғана анкета тіркеу мүмкіндігі бар",
    labels:         {
      ssn: "ЖСН",
    },
    placeholders:   {
      ssn: "ЖСНді енгізіңіз",
    },
    buttons:        {
      remove: "Анкетаны жою",
    },
    confirm:        {
      list:        [
        "Егер анкетаны қалпына келтіргіңіз келсе, ол ақылы болады",
        "Сіз тапсырыстарға ұсынысты жібере алмайсыз",
        "Жаңа анкетаны толтыру мүмкін емес, өйткені бір ЖСНмен тек бір ғана анкета тіркеу мүмкіндігі бар",
      ],
      cancel:      "Жоқ, мен ойланамын",
      remove:      "Анкетаны жою",
      test_inaApp: {
        cancel: "Анкетаны жою",
      },
    },
    validate_limit: {
      title:       "Сізде мүмкіндіктер саны аяқталды",
      description: "Қайталанатын ЖСН тексерулерінің шегінен асып кеттіңіз. 24 сағаттан соң қайталап көріңіз",
      confirm:     "Түсінікті",
    },
  },
  media:             {
    title:  "Фото және видео",
    empty:  {
      specialist: "Мұнда сіздің жұмыстарыңыздың суреті болады, клиенттер суретпен пікір қалдырғаннан соң немесе өзіңіз суретті қосқаннан соң",
      client:     "Мұнда маманның әртүрлі қызмет түрі бойынша жұмысының суреттері болады",
    },
    button: {
      add:      "Суретті қосу",
      update:   "Өзгерту",
      show_all: "Барлығын қарау",
    },
    list:   {
      header: "Фото және видео",
      button: "Файлды қосу",
      remove: {
        title:          {
          one:  "Файлды жою?",
          many: "Файлды жою",
        },
        description:    {
          one:  "Медиафайл қайтарымсыз жойылады",
          many: "{{count}} медиафайлдар қайтарымсыз жойылады",
        },
        select_media:   "Файлды таңдау",
        select_all:     "Барлығын таңдау",
        cancel:         "Бас тарту",
        success_remove: "Медиафайлдар жойылды",
        delete:         {
          one:  "Жою",
          many: "Жою ({{count}})",
        },
      },
      empty:  {
        title:       "Медиафайлдар жоқ",
        description: "Сізде әлі медиафайлдар жоқ. Жұмыс суреттерін толтырып көріңіз, сонда клиенттер сіздің іскерлігіңізге көз жеткізе алатын болады",
        client_review_media:     "Фотосуреттері бар пікірлер жоқ. Жұмыс аяқталғаннан кейін маманмен чатта оған баға қалдырып, жұмыс суреттерін тіркеңіз, олар модерациядан кейін осында пайда болады",
        specialist_review_media: "Фотосуреттері бар пікірлер жоқ.  Жұмыс аяқталғаннан кейін клиенттен баға қалдыруды сұрап, жұмыс суреттерін тіркеуін өтініңіз, олар модерациядан кейін осында пайда болады",
      },
    },
  },
  change_avatar:     {
    title:        "Профиль суретін жүктеу",
    subtitle:     "Сурет мысалы",
    button:       "Суретті жүктеу",
    helper_texts: [
      "Түріңіз толық көрінуі қажет",
      "Күннен қорғайтын көзілдірік пен бас киімсіз",
      "Суретте бөгде адамдарсыз тек сіз болуыңыз керек",
      "Талаптарға сәйкес келмейтін сурет кері қайтарылды",
    ],
  },
  avatar:            {
    on_moderation: "Сурет тексерілді",
    newbie:        "Жаңа қолданушы",
    rating:        "({{count}}) баға",
    upload_fail:   {
      title:       "Жүктеу қатесі",
      description: "Сурет жүктелінбеді. Интернет желісімен байланысты тексеріңіз және қайталап көріңіз",
      buttons:     {
        cancel:    "Бас тарту кері",
        try_again: "Қайта көру",
      },
    },
  },
  favourite:         {
    add:    "Маман “Таңдаулылар” тізімне қосылды",
    delete: "Сіз маманды “Таңдаулылар” тізімінен алып тастадыңыз",
  },
  button:            {
    message: "Жазу",
    call:    "Қоңырау шалу",
    unblock: "Бұғаттан босату",
  },
  call:              "Қоңырау шалу",
  input_placeholder: "Хабарлама...",
  media_upload:      {
    title:         "Файлды қосу",
    subtitle:      "5-тен {{count}} жуктелдi",
    text:          {
      label:       "Қысқаша сипаттама",
      placeholder: "Жүктелген медиафайлдар мен орындалған жұмыстың қысқаша сипаттамасы",
    },
    upload_button: "Қосу",
    remove_media:  {
      title:       "Файлды жою?",
      description: "Сіздің медиафайл қайтарымсыз жойылады",
    },
    close_page:    {
      title:       "Сіз сенімдісіз бе?",
      description: "Егер бетті жапсаңыз, жүктелген суреттер және сіз толтырған ақпараттар сақталмайды",
    },
    toast_message: "Медиафайлдар жүктелді",
  },
}