import React, {useEffect, useState}      from "react";
import {useTranslation}                  from "react-i18next";
import {SubmitHandler, useForm}          from "react-hook-form";
import {BaseInput, InApp}                from "shared/ui";
import {Button}                          from "shared/v12ui";
import {IdentityVerification}            from "components/IdentityVerification";
import {CloseRegistrationInApp}          from "components/CloseRegistrationInApp";
import {useAppSelector}                  from "store";
import {selectSsn}                       from "store/features";
import {useTransferPersonalDataMutation} from "services/registration";
import {setActionForNative}              from "shared/lib";
import {NativeActions}                   from "shared/model";
import {NewPageLayout}                   from "entities/general";
import styles                            from "./styles.module.scss";


const i18n_prefix = "page.registration.transfer.";
const TransferProfile = () => {
  const {t} = useTranslation();
  const contextSsn = useAppSelector(selectSsn);

  const [transferPersonalData, {data, isSuccess}] = useTransferPersonalDataMutation();

  const [ssnErrorInApp, setSsnErrorInApp] = useState(false);
  const [ssnBlockedInApp, setSsnBlockedInApp] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: {isValid},
    setValue,
    trigger,
  } = useForm<{ ssn: string }>({
    defaultValues: {
      ssn: "",
    },
  });

  useEffect(() => {
    if (data && data.errors?.profile === "ssn_not_found") {
      return setSsnErrorInApp(true);
    }
    if (data && data.errors?.profile === "ssn_blocked") {
      return setSsnBlockedInApp(true);
    }
  }, [data]);

  const onSubmit: SubmitHandler<{ ssn: string }> = (data) => transferPersonalData({ssn: data.ssn});

  const handleCancel = () => {
    setValue("ssn", "");
    setSsnErrorInApp(false);
    setSsnBlockedInApp(false);
  }

  const handleOpenBiometric = () => {
    if (data && data.content.url) {
      setActionForNative(NativeActions.OPEN_BIOMETRIC, {url: data.content.url});
    }
  }

  useEffect(() => {
    if (contextSsn) {
      if (contextSsn !== "") {
        setValue("ssn", contextSsn);
        trigger("ssn");
      }
    }
  }, [contextSsn, setValue, trigger]);

  return <>
    {!isSuccess || !data?.status ? <NewPageLayout
        headerTitle={t(`${i18n_prefix}header.title`)}
        headerSubtitle={t(`${i18n_prefix}header.description`)}
        headerRightIcon={<CloseRegistrationInApp />}
        footer={<Button
          text={t("common.next")}
          form="hook_form"
          disabled={!isValid || watch("ssn").length === 0}
          type="submit"
        />}
      >
        <form id="hook_form" className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          <BaseInput
            label={t(`${i18n_prefix}form.ssn_label`)}
            maxLength={12}
            type="tel"
            labelIcon="asterix"
            placeholder={t(`${i18n_prefix}form.ssn_placeholder`)}
            subtext={t(`${i18n_prefix}form.ssn_subtext`)}
            {...register("ssn", {required: true, minLength: 12})}
          />
        </form>
      </NewPageLayout>

      : <IdentityVerification onClick={handleOpenBiometric} />}

    <InApp
      inAppType="image"
      headerText={t("in_app.ssn_error.title")}
      headerSubtext={t("in_app.ssn_error.description")}
      image="/illustrations/no-conversation-3.svg"
      open={ssnErrorInApp}
      onClose={handleCancel}
    >
      <Button hasSpace text={t("in_app.ssn_error.button")} onClick={handleCancel} />
    </InApp>

    <InApp
      inAppType="image"
      image={"/illustrations/no-conversation-3.svg"}
      headerText={t("in_app.ssn_blocked.title")}
      headerSubtext={t("in_app.ssn_blocked.description")}
      open={ssnBlockedInApp}
      onClose={handleCancel}
    >
      <Button hasSpace text={t("in_app.ssn_blocked.button")} onClick={handleCancel} />
    </InApp>
  </>
}

export default TransferProfile;