import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {Nullable}                        from "shared/model";


const initialState: {
  geoInAppIsOpen: boolean,
  galleryInAppIsOpen: boolean,
  cameraInAppIsOpen: boolean,
  geolocationPermission: Nullable<boolean>,
  galleryPermission: Nullable<boolean>,
  cameraPermission: Nullable<boolean>,
} = {
  geolocationPermission: null,
  galleryPermission:     null,
  cameraPermission:      false,
  cameraInAppIsOpen:     false,
  geoInAppIsOpen:        false,
  galleryInAppIsOpen:    false,
};

const slice = createSlice({
  name:     "permissions",
  initialState,
  reducers: {
    setGeolocationPermission(state, action: PayloadAction<Nullable<boolean>>) {
      state.geolocationPermission = action.payload
    },
    setGalleryPermission(state, action: PayloadAction<Nullable<boolean>>) {
      state.galleryPermission = action.payload
    },
    setCameraPermission(state, action: PayloadAction<Nullable<boolean>>) {
      state.cameraPermission = action.payload
    },
    toggleGeoInApp(state, action: PayloadAction<boolean>) {
      state.geoInAppIsOpen = action.payload
    },
    toggleGalleryInApp(state, action: PayloadAction<boolean>) {
      state.galleryInAppIsOpen = action.payload
    },
    toggleCameraInApp(state, action: PayloadAction<boolean>) {
      state.cameraInAppIsOpen = action.payload
    },
  },
})

export const {
  setGeolocationPermission,
  setGalleryPermission,
  setCameraPermission,
  toggleCameraInApp,
  toggleGalleryInApp,
  toggleGeoInApp,
} = slice.actions;

export default slice.reducer;

export const selectGeolocationPermission = (state: {
  permissions: typeof initialState
}) => state.permissions.geolocationPermission;

export const selectGalleryPermission = (state: {
  permissions: typeof initialState
}) => state.permissions.galleryPermission;

export const selectCameraPermission = (state: {
  permissions: typeof initialState
}) => state.permissions.cameraPermission;

export const selectGeoInAppIsOpen = (state: {
  permissions: typeof initialState
}) => state.permissions.geoInAppIsOpen;

export const selectGalleryInAppIsOpen = (state: {
  permissions: typeof initialState
}) => state.permissions.galleryInAppIsOpen;

export const selectCameraInAppIsOpen = (state: {
  permissions: typeof initialState
}) => state.permissions.cameraInAppIsOpen;

