import React            from "react";
import {SkeletonLayout} from "entities/skeleton";
import styles           from "./SkeletonTabs.module.scss";


interface Props {
  className?: string;
}

function SkeletonTabs({className}: Props) {
  return <div className={`${styles.wrapper} ${className}`}>
    {Array.from({length: 4}, (_, index) => <SkeletonLayout key={index} height={33} borderRadius={45} />)}
  </div>
}

export default React.memo(SkeletonTabs);