import React, {useEffect, useState}                from "react";
import {NewPageLayout}                             from "entities/general";
import {useTranslation}                            from "react-i18next";
import {CloseRegistrationInApp}                    from "components/CloseRegistrationInApp";
import {TextBlock, BaseInput}                      from "shared/ui";
import {Button, NewButtonColor, NewButtonType}     from "shared/v12ui";
import {ImageResizeMode, NativeActions}            from "shared/model";
import {setActionForNative, imageUrl}              from "shared/lib";
import {useAppDispatch, useAppSelector}            from "store";
import {selectRegistrationInfo, setIsNotMeFreedom} from "store/features";
import {useFreedomApproveMutation}                 from "services/registration/registration";
import {WaitingScreen}                             from "components/WaitingScreen";
import {useNavigate}                               from "react-router-dom";
import styles                                      from "./styles.module.scss";


const IMG_SIZE = 160;
const i18n_prefix = "page.registration.freedom."

function FF() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectRegistrationInfo);

  const [getApprove, {isSuccess, isLoading}] = useFreedomApproveMutation();

  const [isPassed, setIsPassed] = useState(false);

  const handleNotMeClick = () => {
    dispatch(setIsNotMeFreedom(true));
    navigate("/registration");
  }

  const handleCreateClick = () => getApprove();

  useEffect(() => {
    if (isSuccess) {
      setActionForNative(NativeActions.OPEN_ONBOARDING);
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => setIsPassed(false);
  }, []);

  return <>
    {isPassed
      ? <WaitingScreen />

      : <NewPageLayout
        headerTitle={t(`${i18n_prefix}header.title`)}
        headerRightIcon={<CloseRegistrationInApp isFreedom />}
        footer={<div className={styles.footer}>
          <Button
            text={t(`${i18n_prefix}form.buttons.not_me`)}
            buttonType={NewButtonType.WRAPPED}
            buttonColor={NewButtonColor.GRAY}
            onClick={handleNotMeClick}
            disabled={isLoading}
          />

          <Button
            text={t(`${i18n_prefix}form.buttons.create`)}
            loading={isLoading}
            onClick={handleCreateClick}
          />
        </div>}
      >
        <div className={styles.content}>
          <div className={styles.specialist_info}>
            <div className={styles.avatar_holder}>
              {profile?.avatar && <img
                width={IMG_SIZE}
                height={IMG_SIZE}
                src={imageUrl(profile?.avatar, IMG_SIZE, IMG_SIZE, ImageResizeMode.CROP)}
                alt="avatar"
              />}
            </div>

            <div className={styles.text_info}>
              <div className={styles.text_info_top}>
                {profile?.first_name ?? ""} {profile?.last_name ?? ""}
              </div>

              <div className={styles.text_info_bottom}>
                <div className={styles.rating}>star_filled</div>
                {t("state.guest")}
              </div>
            </div>
          </div>

          <BaseInput readOnly label={t(`${i18n_prefix}form.ssn`)} disabled value={profile?.ssn ?? ""} />

          <BaseInput readOnly label={t(`${i18n_prefix}form.birth_day`)} disabled value={profile?.birth_date ?? ""} />

          <TextBlock description={t(`${i18n_prefix}form.text`)} />
        </div>
      </NewPageLayout>}
  </>
}

export default FF;