import React    from "react";
import Skeleton from "react-loading-skeleton";
import styles   from "./SkeletonLayout.module.scss";
import "react-loading-skeleton/dist/skeleton.css";


interface Props {
  width?: number | string;
  height?: number | string;
  count?: number;
  borderRadius?: number | string;
  containerClass?: string;
  theme?: "light" | "dark";
}

export default function SkeletonLayout({width, height, count, borderRadius = 12, containerClass, theme = "light"}: Props) {
  return <Skeleton
    inline
    width={width}
    height={height}
    count={count === 0 ? 1 : count}
    borderRadius={borderRadius}
    containerClassName={`${containerClass} ${styles.layout}`}
    baseColor={theme === "light" ? "#F7F7F9" : "#EAEAEF"}
    highlightColor={theme === "light" ? "#EAEAEF" :"#D6D6E1"}
  />
}