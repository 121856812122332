import React                             from "react";
import {InApp}                           from "shared/ui";
import {Button, Tabs}                    from "shared/v12ui";
import {filterTimeSlots, formatWeekList} from "./model/formatList";
import styles                            from "./PickOrderDate.module.scss";
import {DateListType}                    from "pages/order/create/model/dateListType";


interface Props {
  open: boolean;
  onClose: (hasSave?: boolean) => void;
  onUpdateDateList: React.Dispatch<React.SetStateAction<DateListType[]>>;
  onUpdateDateAndTime: (date?: string, time?: string) => void;
}

function PickOrderDate({open, onClose, onUpdateDateList, onUpdateDateAndTime}: Props) {
  const [date, setDate] = React.useState<string | undefined>(formatWeekList().find((_, index) => index === 0)?.id);
  const [time, setTime] = React.useState<string | undefined>(filterTimeSlots().find((_, index) => index === 0)?.id);

  const handleSelectTimeClick = () => {
    const currentDate = new Date(date as string);
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    const dateString = `${day}.${month}`;

    onUpdateDateList(prev => prev.map((item) => item.id === "selectDate" ? ({
      ...item,
      text: `${dateString} | ${time}`,
    }) : item));
    onUpdateDateAndTime(date, time);
    onClose(true);
  }

  const handleSetDateClick = (date?: string | number) => {
    setDate(date as string);
    setTime(filterTimeSlots(date as string).find((_, index) => index === 0)?.id);
  }

  return <InApp onClose={() => onClose(false)} open={open} headerText="Выбрать дату и время">
    <div className={styles.wrapper}>
      <Tabs
        type="additional"
        header="Дата"
        currentTab={date}
        list={formatWeekList()}
        onSetCurrentTab={handleSetDateClick}
      />

      <Tabs
        header="Время"
        list={filterTimeSlots(date)}
        currentTab={time}
        onSetCurrentTab={(time) => setTime(time as string)}
      />

      <Button text="Выбрать" hasSpace onClick={handleSelectTimeClick} />
    </div>
  </InApp>
}

export default PickOrderDate;