import React  from "react";
import styles from "./StepIndicator.module.scss";
import cn     from "classnames";


interface Props {
  step: number;
}

function StepIndicator({step}: Props) {
  return <div className={styles.layout}>
    {Array.from({length: 3}).map((_, index) => {
      return <div key={index} className={cn(styles.item, {[styles.active]: step > index + 1})} />
    })}
  </div>
}

export default React.memo(StepIndicator);