import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {Nullable}                        from "shared/model";

interface NotificationItem {
  icon?: string;
  text: string;
}

const initialState: {
  notifications: Nullable<NotificationItem>,
  galleryOpen: boolean,
  currentCity: Nullable<{ id: number, name: string }>,
} = {
  notifications: null,
  galleryOpen:   false,
  currentCity:   null,
};

const slice = createSlice({
  name:     "generals",
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Nullable<NotificationItem>>) {
      state.notifications = action.payload
    },
    setGalleryOpen(state, action: PayloadAction<boolean>) {
      state.galleryOpen = action.payload
    },
    removeNotification(state) {
      state.notifications = null
    },
    setCurrentCity(state, action: PayloadAction<Nullable<{ id: number, name: string }>>) {
      state.currentCity = action.payload
    },
  },
})

export const {addNotification, removeNotification, setGalleryOpen, setCurrentCity} = slice.actions;
export default slice.reducer;

export const selectNotificationList = (state: { generals: typeof initialState }) => state.generals.notifications;
export const selectGalleryOpen = (state: { generals: typeof initialState }) => state.generals.galleryOpen;
export const selectCurrentCity = (state: { generals: typeof initialState }) => state.generals.currentCity;

