import {ImageResizeMode} from "../../../model";

export const imageUrl = (digest: string, height?: number, width?: number, mode: ImageResizeMode = ImageResizeMode.RESIZE) => {
  const url = new URL(`${process.env.REACT_APP_UPLOAD_URL}/` + digest);
  if (height) {
    url.searchParams.set("h", `${height}`);
  }
  if (width) {
    url.searchParams.set("w", `${width}`);
  }
  url.searchParams.set("m", mode);

  return url.toString();
}

export const videoPreviewUrl = (digest: string, height?: number, width?: number, mode: ImageResizeMode = ImageResizeMode.RESIZE) => {
  const url = new URL(`${process.env.REACT_APP_UPLOAD_VIDEO_URL}/` + digest + `/preview`);
  if (height) {
    url.searchParams.set("h", `${height}`);
  }
  if (width) {
    url.searchParams.set("w", `${width}`);
  }
  url.searchParams.set("m", mode);

  return url.toString();
}