import React                                                       from "react";
import {useTranslation}                                            from "react-i18next";
import {NewPageLayout}                                             from "entities/general/newPageLayout";
import {SubmitHandler, useFieldArray, useForm}                     from "react-hook-form";
import {Button, Icon, NewButtonColor, NewButtonType, NewType}      from "shared/v12ui";
import {setActionForNative}                                        from "shared/lib";
import {NativeActions}                                             from "shared/model";
import {InApp}                                                     from "shared/ui";
import {CreateState}                                               from "./model/createState";
import {useFetchOrderDetails}                                      from "./model/fetchOrderDetails";
import {DateListType}                                              from "./model/dateListType";
import {getCurrentDate, getTomorrowDay}                            from "./model/formatDates";
import FirstStepOrder                                              from "./ui/FirstStepOrder";
import SecondStepOrder                                             from "./ui/SecondStepOrder";
import ThirdSteOrder                                               from "./ui/ThirdSteOrder";
import {useFetchOrderRecommendationMutation, useSaveOrderMutation} from "./api";
import styles                                                      from "./CreateOrder.module.scss";

export default function CreateOrder() {
  const {t} = useTranslation();
  const {orderDetails} = useFetchOrderDetails({ticketId: undefined});
  const [createOrder] = useSaveOrderMutation();
  const [getRecommendation, {
    data:      recommendations,
    isLoading: recommendationsIsLoading,
  }] = useFetchOrderRecommendationMutation();

  const [dateList, setDateList] = React.useState<DateListType[]>([
    {id: "fast", text: "Срочно"},
    {id: getCurrentDate(), text: "Сегодня"},
    {id: getTomorrowDay(), text: "Завтра"},
    {id: "selectDate", text: "Выбрать дату и время", rightIcon: "keyboard_arrow_down"},
  ]);

  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: {errors},
    setError,
    clearErrors,
  } = useForm<CreateState>({
    defaultValues: {
      description:           "",
      price:                 undefined,
      isAppPaymentWay:       false,
      addresses:             [],
      priceUnit:             undefined,
      phone:                 undefined,
      datePick:              undefined,
      date:                  undefined,
      time:                  undefined,
      step:                  1,
      recommendationsIsOpen: false,
      isAllowed:             false,
      isFast:                false,
    },
  });

  const {fields, remove} = useFieldArray({control, name: "addresses"});

  const handleIncreaseStep = () => setValue("step", watch("step") + 1);

  const handleButtonClick = () => {
    if (recommendationsIsLoading) return;
    if (watch("recommendationsIsOpen")) return;
    const step = watch("step");

    if (step === 3) {
      return handleSubmit(onSubmit)();
    }
    if (step === 1) {
      if (watch("description").trim() === "") {
        setError("description", {message: "Поле обязательно для заполнения"});
        return;
      }
      if (recommendations?.alert) {
        return handleIncreaseStep();
      }
      return getRecommendation({description: watch("description")}).then((response) => {
        //@ts-ignore
        if (!response.data.alert) {
          return handleIncreaseStep();
        }
      });
    }
    return handleIncreaseStep();
  }

  const handleBackStepClick = () => setValue("step", watch("step") - 1);

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  const onSubmit: SubmitHandler<CreateState> = (data) => {
    const addresses = data.addresses.map((address) => ({
      address:   address.name,
      longitude: address.coord[0],
      latitude:  address.coord[1],
      city_id:   address.city_id,
    }));

    const date = data.datePick !== "fast" && data.datePick !== "selectDate" ? data.datePick : undefined;
    createOrder({
      // ticket_id?: number;
      // work_id?: number;
      // is_allowed:   data.communicationMethod === 1,
      is_allowed:   false,
      description:  data.description,
      addresses:    addresses ?? [],
      work_unit_id: null,
      price_to:     data.price,
      is_fast:      data.datePick === "fast",
      // media               ? : {
      //   digest: string;
      //   type:   string,;
      // }[];
      // date                ? : string;
      // time                ? : string;
      is_app_payment_way: data.isAppPaymentWay,
      date,
    });
  }

  React.useEffect(() => {
    if (orderDetails) {
      const {ticket} = orderDetails;
      const data = {
        step:             1,
        description:      ticket.description,
        price:            ticket.price_to ? ticket.price_to : undefined,
        isAppPaymentWay:  orderDetails.ticket.is_app_payment_way,
        isAllowed:        ticket.is_allowed,
        addresses:        ticket.addresses?.map((address) => ({
          coord:   [+address.longitude, +address.latitude],
          name:    address.address,
          city_id: address.city.id,
        })) ?? [],
        priceUnit:        ticket.work_units?.find((item) => item.is_selected)?.name,
        workUnits:        ticket.work_units ?? [],
        phone:            ticket.phone.slice(2),
        datePick:         ticket.is_fast ? "fast" : ticket.date,
        recommendedPrice: ticket.recommended_price,
        isFast:           ticket.is_fast,
      }

      reset(data);

      if (ticket.date) {
        if (new Date(ticket.date) < new Date(getCurrentDate())) {
          const currentDate = new Date(ticket.date);
          const month = currentDate.getMonth() + 1;
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();

          const dateString = `${day}.${month}.${year}`;
          setDateList(prev => ([{id: ticket.date as string, text: dateString}, ...prev]));
        }
      }
    }
  }, [orderDetails]);

  React.useEffect(() => {
    if (recommendations) {
      if (recommendations.alert) {
        setValue("recommendationsIsOpen", true);
      }
      if (recommendations.ticket) {
        setValue("recommendedPrice", recommendations.ticket.recommended_price);
        setValue("workUnits", recommendations.ticket.work_units);
      }
    }
  }, [recommendations]);

  return <>
    <form onSubmit={handleSubmit(onSubmit)} style={{height: "100%"}}>
      <NewPageLayout
        headerRightIcon={<Icon onClick={handleOnCloseClick} iconColor={"#2B2B2B"}>close</Icon>}
        headerTitle={t("page.order.order_create.header.title")}
        headerSubtitle={t(`page.order.order_create.header.subtitle.${watch("step") - 1}`)}
        step={watch("step")}
        footer={<Button
          type="button"
          additionalIcon="keyboard_arrow_left"
          viewType={watch("step") === 1 ? NewType.VERTICAL : NewType.HORIZONTAL}
          onClick={handleButtonClick}
          onAdditionalButtonClick={handleBackStepClick}
          buttonColor={watch("step") !== 3 ? NewButtonColor.BLACK : NewButtonColor.SUCCESS}
          text={watch("step") !== 3 ? t("common.next") : t("page.order.order_create.create_button")}
          loading={recommendationsIsLoading}
          disabled={!!errors.description}
        />}
      >
        {watch("step") === 1 && <FirstStepOrder
          watch={watch}
          errors={errors}
          control={control}
          register={register}
          labels={orderDetails?.labels?.media}
          setValue={setValue}
          dateList={dateList}
          clearErrors={clearErrors}
          onSetDateList={setDateList}
        />}

        {watch("step") === 2 && <SecondStepOrder
          watch={watch}
          control={control}
          register={register}
          labels={orderDetails?.labels?.app_payment}
          workUnits={watch("workUnits")}
          setValue={setValue}
        />}

        {watch("step") === 3 &&
          <ThirdSteOrder
            register={register}
            watch={watch}
            control={control}
            setValue={setValue}
            fields={fields}
            labels={orderDetails?.labels?.addresses}
            remove={remove}
          />}
      </NewPageLayout>
    </form>

    <InApp
      inAppType="image"
      image="/illustrations/product-manual-2.svg"
      headerText={recommendations?.alert?.title}
      headerSubtext={<div className={styles.inAppSubtext}>
        {recommendations?.alert?.text.split("\n").map((item, index) => {
          return <span key={index}>{item}</span>
        })}
      </div>}
      open={watch("recommendationsIsOpen")}
      onClose={() => setValue("recommendationsIsOpen", false)}
    >
      <div className={styles.inApp_buttons_holder}>
        <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          text="Дополнить описание"
          onClick={() => setValue("recommendationsIsOpen", false)}
        />
        <Button text="Оставить как есть" onClick={() => {
          setValue("recommendationsIsOpen", false);
          handleIncreaseStep();
        }} hasSpace />
      </div>
    </InApp>
  </>
};