import React            from "react";
import {useTranslation} from "react-i18next";
import {ButtonDefault}  from "shared/ui";
import styles           from "./styles.module.scss";


interface Props {
  onClick: () => void;
}

export default function ImageLoadError({onClick}: Props) {
  const {t} = useTranslation();

  return <div className={styles.wrapper}>
    <span className={styles.icon_holder}>cancel</span>
    <div className={styles.title}>{t("elements.image_load_error.title")}</div>
    <div className={styles.description}>{t("elements.image_load_error.description")}</div>
    <div className={styles.button}>
      <ButtonDefault onClick={onClick} buttonRound>{t("elements.image_load_error.button")}</ButtonDefault>
    </div>
  </div>
}