import React                                          from "react";
import {
  YMap,
  YMapDefaultSchemeLayer,
  reactify,
  YMapDefaultFeaturesLayer,
  YMapListener,
  YMapMarker,
  YMapControl,
}                                                     from "../../lib/yamaps";
import styles                                         from "./YaMap.module.scss";
import {Button, Icon, Input, NewList}                 from "shared/v12ui";
import {SuggestResponseItem}                          from "@yandex/ymaps3-types/imperative/suggest";
import GeneralMark                                    from "./ui/GeneralMark";
import MarkWithNumber                                 from "./ui/MarkWithNumber";
import {AddressesItem}                                from "./model/addressItem";
import {LngLat, YMapLocationRequest}                  from "@yandex/ymaps3-types";
import {formatAddressName, yaMapSearch, yaMapSuggest} from "./model/yaMapHelpers";
import {customLayer}                                  from "./model/yaMapCustomSchemeLayer";
import {useFetchAddressesHistoryQuery}                from "pages/order/create";
import {CitySelect}                                   from "features/global/citySelect";
import {NavigationIcon}                               from "./model/navigationIcon";
import {createPortal}                                 from "react-dom";
import {Nullable}                                     from "shared/model";
import {GeolocationRequest}                           from "features/permissions/geolocation";
import {useAppDispatch, useAppSelector}               from "store";
import {selectGeolocationPermission, toggleGeoInApp}  from "features/permissions";


interface Props {
  currentCity: Nullable<{ id: number, name: string }>;
  addresses: AddressesItem[];
  addressName?: string;
  onSetAddress: (item: AddressesItem) => void;
  onClose: () => void;
}

const LOCATION: YMapLocationRequest = {
  center: [71.427552, 51.128064],
  zoom:   15,
};

function YaMap({addressName, addresses: propAddresses, currentCity, onSetAddress, onClose}: Props) {
  const dispatch = useAppDispatch();

  const debounceTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const hasGeoPermission = useAppSelector(selectGeolocationPermission);

  const {data: addressesHistoryList} = useFetchAddressesHistoryQuery();

  const [suggestValue, setSuggestValue] = React.useState("");
  const [suggests, setSuggests] = React.useState<SuggestResponseItem[]>([]);
  const [openSearch, setOpenSearch] = React.useState(true);
  const [searchHeight, setSearchHeight] = React.useState<string>("calc(100% - 9.4rem - env(safe-area-inset-top))");
  const [addresses, setAddresses] = React.useState<AddressesItem[]>([]);
  const [location, setLocation] = React.useState<YMapLocationRequest>(LOCATION);
  const [openCitySelect, setOpenCitySelect] = React.useState(false);
  const [currentGeo, setCurrentGeo] = React.useState<number[]>([]);

  const handleToggleCitySelect = () => setOpenCitySelect(prev => !prev);

  const handleCitySelectClick = () => handleToggleCitySelect();

  const handleToggleSearch = () => {
    if (searchHeight === "auto") {
      setSearchHeight("calc(100% - 9.4rem - env(safe-area-inset-top))");
      setOpenSearch(true);
    } else {
      setSearchHeight("auto");
      setOpenSearch(false);
    }
  }

  const handleSearchPlace = (event: React.ChangeEvent<HTMLInputElement>) => {
    yaMapSuggest({text: `Астана ${event.target.value}`}).then((response) => {
      setSuggests(response)
    });
    setSuggestValue(event.target.value);
  }

  const handleOnSuggestClick = (text: string) => {
    if (!text) return;
    yaMapSearch({text: text, limit: 1}).then((res) => {
      setLocation({center: res[0].geometry?.coordinates as LngLat, zoom: 17});
    });
    handleToggleSearch();
  }

  const handleOnInputClick = () => {
    if (openSearch) return;
    handleToggleSearch()
  }

  const handleSetAddressClick = () => {
    yaMapSearch({text: suggestValue, limit: 1}).then((res) => {
      onSetAddress({
        coord:   res[0].geometry?.coordinates as number[],
        name:    formatAddressName(suggestValue) ?? "",
        city_id: currentCity?.id,
      });
      onClose();
    });
  }

  const handleHistoryAddressClick = (value: string) => {
    yaMapSearch({text: value, limit: 1}).then((res) => {
      onSetAddress({
        coord:   res[0].geometry?.coordinates as number[],
        name:    formatAddressName(value) ?? "",
        city_id: currentCity?.id,
      });
      onClose();
    });
  }

  const handleGetCurrentGeolocationClick = () => {
    if (hasGeoPermission === false) {
      return dispatch(toggleGeoInApp(true));
    } else {
      return setLocation({center: currentGeo as LngLat, zoom: 15});
    }
  }

  React.useEffect(() => {
    if (propAddresses) {
      setAddresses(propAddresses);
    }
  }, [propAddresses]);

  React.useEffect(() => {
    if (addressName) {
      setSuggestValue(addressName);
      yaMapSuggest({text: `Астана ${addressName}`}).then((response) => {
        setSuggests(response)
      });
    }
  }, [addressName]);

  React.useEffect(() => {
    if (currentCity) {
      yaMapSearch({text: `Казахстан, ${currentCity.name}`, limit: 1}).then((res) => {
        setLocation({center: res[0].geometry?.coordinates as LngLat, zoom: 15});
      })
    }
  }, [currentCity]);

  React.useEffect(() => {
    let stopScrolling = false;

    function handleTouchMove(e: TouchEvent) {
      if (!stopScrolling) {
        return;
      }
      e.preventDefault();
    }

    function onTouchStart() {
      stopScrolling = true;
    }

    function onTouchEnd() {
      stopScrolling = false;
    }

    window.addEventListener("touchstart", onTouchStart);
    window.addEventListener("touchend", onTouchEnd);
    window.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
  }, [])

  return <>
    {createPortal(<div className={styles.wrapper}>
      <div className={styles.shadow_holder}>
        <div className={styles.header_content_holder}>
          <button className={styles.close_button} type="button" onClick={onClose}>close</button>
          <div className={styles.city_select} onClick={handleCitySelectClick}>
            <span className={styles.city_select_text}>{`г. ${currentCity?.name}`}</span>
            <Icon size={16} iconColor="#828291">keyboard_arrow_down</Icon>
          </div>
        </div>
      </div>

      <YMap
        mode="vector"
        location={reactify.useDefault(location, [location])}
        copyrights={false}
      >
        <YMapDefaultFeaturesLayer />
        <YMapDefaultSchemeLayer customization={customLayer} />
        <YMapControl>
          <YMapListener
            onUpdate={(event) => {
              if (openSearch) return;
              if (!event.mapInAction) {
                if (debounceTimeout.current) {
                  clearTimeout(debounceTimeout.current);
                }
                debounceTimeout.current = setTimeout(() => {
                  yaMapSearch({text: `${event.location.center}`, limit: 1})
                    .then((res) => {
                      setSuggestValue(res[0]?.properties?.name ?? "Астана");
                    })
                }, 1000);
              }
            }}
          />
        </YMapControl>

        {addresses.map((address, index) => {
          return <YMapMarker
            key={index}
            coordinates={reactify.useDefault(address.coord as LngLat, [addresses])} draggable={false}>
            <section className={styles.marker_holder}>
              <MarkWithNumber count={index + 1} />
            </section>
          </YMapMarker>
        })}

        <div className={styles.geolocation_button} onClick={handleGetCurrentGeolocationClick}>
          <NavigationIcon />
        </div>

        <div className={styles.centered_mark}>{!addresses.length ? <GeneralMark /> : <GeneralMark />}</div>

        <div className={styles.search} style={{height: searchHeight}}>
          <div className={styles.search_wrapper}>
            <Input
              icon="search"
              placeholder="Поиск"
              value={suggestValue}
              readOnly={!openSearch}
              onClick={handleOnInputClick}
              onChange={handleSearchPlace}
              hasSpace={false}
              subtext={(!openSearch && !hasGeoPermission)
                ? <div className={styles.permission_subtext}>
                  <span>Разрешите приложению определить вашу геолокацию, чтобы определить ваш адрес. </span>
                  <span onClick={handleGetCurrentGeolocationClick}>Разрешить</span>
                </div>
                : undefined}
            />

            {openSearch && <button
              type="button"
              className={styles.map_button}
              onClick={handleToggleSearch}
            >
              Карта
            </button>}
          </div>

          {!openSearch && <div className={styles.select_address_holder}>
            <Button type="button" onClick={handleSetAddressClick} text="Выбрать" hasSpace />
          </div>}

          {openSearch && <ul className={styles.suggests_wrapper}>
            {!suggestValue
              ? <>
                {/*<li>*/}
                {/*  <NewList*/}
                {/*    hasSpace={false}*/}
                {/*    icon={<NavigationIcon />}*/}
                {/*    title="Мое местоположение"*/}
                {/*    subtitle="Определить по GPS"*/}
                {/*    // onClick={() => handleOnSuggestClick(suggestItem.title.text)}*/}
                {/*  />*/}
                {/*</li>*/}
                {addressesHistoryList?.addresses.map((address, index) => {
                  return <li key={index}>
                    <NewList
                      hasSpace={false}
                      icon="history"
                      title={address.address}
                      subtitle={`г. ${address.city.name}`}
                      onClick={() => handleHistoryAddressClick(address.address)}
                    />
                  </li>
                })}
              </>
              : <>{suggests.map((suggestItem, index) => {
                return <li key={index}>
                  <NewList
                    hasSpace={false}
                    icon="place"
                    title={formatAddressName(suggestItem.title.text) ?? ""}
                    subtitle={"г. Астана"}
                    onClick={() => handleOnSuggestClick(suggestItem.title.text)}
                  />
                </li>
              })}</>}
          </ul>}
        </div>
      </YMap>

      <CitySelect open={openCitySelect} onClose={handleToggleCitySelect} />

      <GeolocationRequest setCurrentGeo={setCurrentGeo} />
    </div>, document.body)}
  </>
}

export default YaMap;