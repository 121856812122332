import React, {HTMLAttributes} from "react";
import cn from "classnames";
import styles                  from "./Icon.module.scss"


interface Props extends HTMLAttributes<HTMLSpanElement> {
  size?: number;
  iconColor?: string;
  isNew?: boolean;
}

export default function Icon({children, className = "", size, iconColor, isNew = false, ...rest}: Props) {
  const style = {
    fontSize:  size ? `${size / 10}rem` : undefined,
    color:     iconColor ? iconColor : undefined,
    overflow:  "hidden",
    minWidth:  "1.6rem",
    minHeight: "1.6rem",
  }

  return <span
    className={cn(styles.icon, className, {[styles.new]: isNew})}
    style={style}
    {...rest}
  >
      {children}
  </span>
};