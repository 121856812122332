import React, {useEffect} from "react";
import styles             from "./YandexAd.module.scss";


interface Props {
  adParams?: { [key: string]: string };
}

export default function YandexAd({adParams}: Props) {
  useEffect(() => {
    if (!adParams) return;
    //@ts-ignore
    window.yaContextCb.push(() => {
      //@ts-ignore
      Ya.adfoxCode.create({
        ownerId:     11622371,
        containerId: "adfox_17260387840576517",
        params:      {
          p1: "ddyrn",
          p2: "jdot",
          ...adParams,
        },
      })
    })
  }, [adParams]);

  return <div className={styles.ad} id="adfox_17260387840576517"></div>
};