import React                           from "react";
import {NewPageLayout, SystemResponse} from "entities/general";
import {Button, Icon, Input, NewList}  from "shared/v12ui";
import {InApp, RadioInput}             from "shared/ui";
import {Nullable}                      from "shared/model";
import {useDebounceValue}              from "shared/lib";
import {useAppDispatch}                from "store";
import {setCurrentCity}                from "store/features";
import {
  useFetchRegionListQuery,
  useLazyFetchCitiesByRegionListQuery,
  useLazyFetchCountryListQuery,
}                                      from "./api";
import styles                          from "./CitySelect.module.scss";
import {createPortal}                  from "react-dom";


interface Props {
  open: boolean;
  onClose: () => void;
}

function CitySelect({open, onClose}: Props) {
  const dispatch = useAppDispatch();

  const [openCitiesList, setOpenCitiesList] = React.useState(false);
  const [openCountryList, setOpenCountryList] = React.useState(false);
  const [currentCity, setCity] = React.useState<Nullable<{ id: number, name: string }>>(null);
  const [countryId, setCountryId] = React.useState<Nullable<number>>(null);
  const [query, setQuery] = React.useState("");

  const debouncingValue = useDebounceValue(query, 1000) as string;

  const {data} = useFetchRegionListQuery();
  const [fetchCities, {data: region}] = useLazyFetchCitiesByRegionListQuery();
  const [fetchCountryList, {data: countryList}] = useLazyFetchCountryListQuery();

  const handleFetchCitiesClick = (id: number) => {
    fetchCities({id}).then((res) => res && setOpenCitiesList(true));
  }

  const handleCloseCitySelectInApp = () => setOpenCitiesList(false);

  const handleCloseCountrySelectInApp = () => setOpenCountryList(false);

  const handleListClick = (id: number, name: string) => setCity({id, name});

  const handleSelectCityClick = (id: number, name: string) => setCity({id, name});

  const handleSetQuery = (event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

  const handleFetchCountriesClick = () => {
    fetchCountryList().then((res) => res && setOpenCountryList(true));
  }

  const onConfirmClick = () => {
    dispatch(setCurrentCity(currentCity));
    onClose();
  };

  React.useEffect(() => {
    if (data) {
      const selectedCity = data.regions.find((region) => region.is_selected);
      const currentCountry = data.country?.id;
      setCity(selectedCity ?? null);
      setCountryId(currentCountry ?? null);
    }
  }, [data]);

  React.useEffect(() => {
    if (debouncingValue) {
      fetchCities({query: debouncingValue});
    }
  }, [debouncingValue]);

  if (!open) return null;

  return <>
    {createPortal(<div className={styles.wrapper}>
      <NewPageLayout
        headerTitle="Выбор города"
        headerRightIcon={<Icon onClick={onClose} className="text black">close</Icon>}
        headerLeftIcon={<div className={styles.country_select} onClick={handleFetchCountriesClick}>
          <img alt="" src="/images/country_kz_2.svg" />
        </div>}
        footer={<Button onClick={onConfirmClick} text="Готово" />}
        pageHeaderChildren={<Input
          hasSpace={false}
          placeholder="Поиск"
          icon="search"
          value={query}
          onChange={handleSetQuery}
        />}
      >
        <div className={styles.content}>
          {debouncingValue
            && !region?.cities.length
            && <SystemResponse
              icon="search"
              title="Ничего не найдено"
              description="Возможно вы ввели запрос с ошибкой или такой город не подключен к нашему сервису"
            />}
          <ul>
            {debouncingValue
              ? region?.cities.map(({id, name}) => {
                return <li key={id}>
                  <NewList
                    title={name}
                    onClick={() => handleListClick(id, name)}
                    controlIcon={<RadioInput
                      isNew
                      name="city_id"
                      label=""
                      value={id}
                      checked={id === currentCity?.id}
                      onChange={() => handleSelectCityClick(id, name)}
                    />}
                  />
                </li>
              })
              : data?.regions.map(({id, name, region_id}, index) => {
                return <li key={`${id}-${index}`}>
                  <NewList
                    title={name}
                    onClick={() => {
                      if (region_id) {
                        return handleListClick(id, name);
                      }
                      return handleFetchCitiesClick(id);
                    }}
                    controlIcon={!region_id
                      ? <Icon>keyboard_arrow_right</Icon>
                      : <RadioInput
                        isNew
                        name="city_id"
                        label=""
                        value={id}
                        checked={id === currentCity?.id}
                        onChange={() => handleSelectCityClick(id, name)}
                      />}
                  />
                </li>
              })}
          </ul>

          <InApp headerText="Выбор города" open={openCitiesList} onClose={handleCloseCitySelectInApp}>
            <div className={styles.inApp_content_holder}>
              {region?.cities.map(({id, name}) => {
                return <NewList
                  key={id}
                  title={name}
                  onClick={() => handleListClick(id, name)}
                  controlIcon={<RadioInput
                    isNew
                    label=""
                    name="city_id"
                    value={id}
                    checked={id === currentCity?.id}
                    onChange={() => handleSelectCityClick(id, name)}
                  />}
                />
              })}

              <Button text="Готово" hasSpace onClick={handleCloseCitySelectInApp} />
            </div>
          </InApp>

          <InApp headerText="Выбор города" open={openCitiesList} onClose={handleCloseCitySelectInApp}>
            <div className={styles.inApp_content_holder}>
              {region?.cities.map(({id, name}) => {
                return <NewList
                  key={id}
                  title={name}
                  onClick={() => handleListClick(id, name)}
                  controlIcon={<RadioInput
                    isNew
                    label=""
                    name="city_id"
                    value={id}
                    checked={id === currentCity?.id}
                    onChange={() => handleSelectCityClick(id, name)}
                  />}
                />
              })}

              <Button text="Готово" hasSpace onClick={handleCloseCitySelectInApp} />
            </div>
          </InApp>

          <InApp headerText="Выбрать страну" open={openCountryList} onClose={handleCloseCountrySelectInApp}>
            <div className={styles.inApp_content_holder}>
              {countryList?.countries.map(({id, name}) => {
                return <NewList
                  key={id}
                  title={name}
                  onClick={() => setCountryId(id)}
                  controlIcon={<RadioInput
                    isNew
                    label=""
                    name="city_id"
                    value={id}
                    checked={id === countryId}
                    onChange={(event) => setCountryId(+event.target.value)}
                  />}
                />
              })}

              <Button text="Готово" hasSpace onClick={handleCloseCountrySelectInApp} />
            </div>
          </InApp>
        </div>
      </NewPageLayout>
    </div>, document.body)}
  </>
}

export default React.memo(CitySelect);