import {createContext, MutableRefObject, ReactNode, useContext, useRef} from "react";
import {Gallery}                                                        from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css"
import PhotoSwipeCore                                                   from "photoswipe";
import {useNativeHandler}                                               from "../../lib";
import {NativeActions}                                                  from "../../model";
import {useAppDispatch}                                                 from "../../../store";
import {setGalleryOpen}                                                 from "../../../store/features";


interface Props {
  children: ReactNode;
}

const PhotoSwipeContext = createContext<MutableRefObject<null | PhotoSwipeCore> | null>(null);

export default function GalleryWrapper({children}: Props) {
  const dispatch = useAppDispatch();
  const pswpRef = usePhotoSwipe();

  const createPlugin = () => (photoswipeLightbox: any) => {
    photoswipeLightbox.on("init", () => {
      const {pswp} = photoswipeLightbox;

      if (pswp) {
        dispatch(setGalleryOpen(true));
        pswpRef.current = pswp;

        pswp.on("destroy", () => {
          dispatch(setGalleryOpen(false));
          pswpRef.current = null;
        });
      }
    });

    photoswipeLightbox.on("close", () => {
      dispatch(setGalleryOpen(false));
      pswpRef.current = null;
    });
  };

  const foo = () => {
    if (pswpRef.current) {
      pswpRef.current.close();
    } else {
    }
  }

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    foo()
  });

  const plugin = createPlugin();

  return <Gallery
    options={{
      showHideAnimationType:   "fade",
      loop:                    false,
      zoom:                    false,
      clickToCloseNonZoomable: false,
      imageClickAction:        false,
      bgClickAction:           false,
      tapAction:               false,
      closeOnVerticalDrag:     true,
      allowPanToNext:          false,
    }}
    plugins={plugin}
  >
    {children}
  </Gallery>
}


export const PhotoSwipeProvider = ({children}: { children: React.ReactNode }) => {
  const pswpRef = useRef<null | PhotoSwipeCore>(null);
  return (
    <PhotoSwipeContext.Provider value={pswpRef}>
      {children}
    </PhotoSwipeContext.Provider>
  );
};

export const usePhotoSwipe = () => {
  const context = useContext(PhotoSwipeContext);
  if (!context) {
    throw new Error("usePhotoSwipe must be used within a PhotoSwipeProvider");
  }
  return context;
};