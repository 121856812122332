import React                                  from "react";
import {useNavigate}                          from "react-router-dom";
import {useTranslation}                       from "react-i18next";
import {useGetTariffPeriodsQuery}             from "services/specialists/tariffs";
import {SkeletonLayout}                       from "entities/skeleton";
import {NewPageLayout}                        from "entities/general";
import {ButtonDefault}                        from "shared/ui";
import {Icon}                                 from "shared/v12ui";
import {NativeActions}                        from "shared/model";
import {useNativeHandler, setActionForNative} from "shared/lib";
import styles                                 from "./styles.module.scss";
import {selectPurchaseIsLoading}              from "store/features";
import {useAppSelector}                       from "store";
import PeriodListItem                         from "./ui/PeriodListItem";


const i18n_prefix = "page.specialist.tariff_periods."
const PeriodsListPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);

  const {data, isLoading: periodsLoading, isFetching: periodsIsFetching, refetch} = useGetTariffPeriodsQuery(null);

  const handleBackClick = () => navigate("/tariffs");

  const handleMoveToCategoriesClick = () => setActionForNative(NativeActions.OPEN_NEWSLINE_FILTERS);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    return handleBackClick();
  });

  useNativeHandler<{ is_changed: boolean }>("tariffs", NativeActions.OPEN_NEWSLINE_FILTERS, data => {
    if (data.is_changed) {
      refetch();
    }
  });

  React.useEffect(() => {
    const analytics = data?.analytics?.["tariff list"];
    if (analytics) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist tariff duration selection opened",
        properties: {
          "tariff list": analytics,
        },
      });
    }
  }, [data]);

  const footer = <div className={styles.footer}>
    {periodsLoading || periodsIsFetching
      ? <>
        <SkeletonLayout height={19} />
        <SkeletonLayout height={19} width="95%" />
        <SkeletonLayout height={19} width="60%" />
      </>

      : !data?.content.items.length
        ? null
        : <span>{t(`${i18n_prefix}footer`)}</span>}
  </div>

  return <NewPageLayout
    headerTitle={t(`${i18n_prefix}header.title`)}
    headerSubtitle={t(`${i18n_prefix}header.subtitle`)}
    headerLeftIcon={<Icon iconColor="#2B2B2B" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
    footer={footer}
    isLoading={periodsLoading}
  >
    <>
      {periodsLoading || periodsIsFetching
        ? <>{[...Array(3)].map((_, index) => <SkeletonLayout containerClass="mgb-6" key={index} height={61} />)}</>

        : !data?.content.items.length
          ? <div className={styles.period_plug}>
            <div className={styles.plug_content}>
              <Icon size={40} className="text gray mgb-3">search</Icon>
              <div className={styles.plug_content_text}>
                <div className={styles.plug_content_title}>{t(`${i18n_prefix}plug.title`)}</div>
                <div className={styles.plug_content_description}>
                  {t(`${i18n_prefix}plug.subtitle`)}
                </div>
              </div>
              <ButtonDefault
                onClick={handleMoveToCategoriesClick}
                buttonRound
              >
                {t(`${i18n_prefix}plug.button`)}
              </ButtonDefault>
            </div>
          </div>
          : data?.content?.items.map((period, index) => <PeriodListItem key={index} period={period} />)}
    </>
  </NewPageLayout>
}

export default PeriodsListPage;