import React                       from "react";
import {useFetchOrderDetailsQuery} from "../api";
import {useSetHeaders}             from "shared/lib";
import {useAppDispatch}            from "store";
import {setCurrentCity}            from "store/features";


interface Props {
  ticketId?: number;
}

export function useFetchOrderDetails({ticketId}: Props) {
  const dispatch = useAppDispatch();
  const {isSuccess} = useSetHeaders();
  const {data: orderDetails} = useFetchOrderDetailsQuery({ticket_id: ticketId}, {skip: !isSuccess});

  React.useEffect(() => {
    if (orderDetails) {
      dispatch(setCurrentCity(orderDetails.city));
    }
  }, [orderDetails]);

  return {orderDetails};
}