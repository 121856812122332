import React  from "react";
import styles from "./Tabs.module.scss";
import cn     from "classnames";


interface NewTabItem {
  text: string;
  subtext?: string;
  icon?: string;
  rightIcon?: string;
  id: number | string;
}


interface Props {
  header?: string;
  list: NewTabItem[];
  children?: React.ReactNode;
  currentTab?: string | number;
  type?: "standard" | "additional";
  onSetCurrentTab: (value?: string | number) => void;
  isFullPosition?: boolean;
}

function Tabs({header, list, children, currentTab: propTab, onSetCurrentTab, type = "standard", isFullPosition = false}: Props) {
  const [currentTab, setCurrentTab] = React.useState<string | number | undefined>(undefined);

  const handleSetCurrentTab = (id?: number | string) => {
    setCurrentTab(id);
    onSetCurrentTab(id);
  }

  React.useEffect(() => {
    setCurrentTab(propTab);
  }, [propTab]);

  return <div className={styles.wrapper}>
    {header && <span className={styles.header}>{header}</span>}

    <ul className={cn(styles.tabs_holder, {[styles.full_position]: isFullPosition})}>
      {list.map((item) => {
        if (type === "standard") {
          return <li
            key={item.id}
            className={cn(styles.tab, {[styles.active]: currentTab === item.id})}
            onClick={() => handleSetCurrentTab(item.id)}
          >
            {item.icon && <span className={`${styles.tab_icon} mgr-2`}>{item.icon}</span>}
            <span className={styles.tab_text}>{item.text}</span>
            {item.rightIcon && <span className={`${styles.tab_icon} mgl-2`}>{item.rightIcon}</span>}
          </li>
        }
        return <li
          key={item.id}
          className={cn(styles.additional_item, {[styles.active]: currentTab === item.id})}
          onClick={() => handleSetCurrentTab(item.id)}
        >
          <span className={styles.title}>{item.text}</span>
          <span className={styles.subtext}>{item.subtext}</span>
        </li>
      })}
    </ul>

    {children && <div className="mgt-2">{children}</div>}
  </div>
}

export default React.memo(Tabs);