import React                                     from "react";
import {useTranslation}                          from "react-i18next";
import {GalleryWrapper, InApp, Label, LabelItem} from "shared/ui";
import {Button, Icon, IconType, NewList}         from "shared/v12ui";
import {setActionForNative, useNativeHandler}    from "shared/lib";
import {NativeActions}                           from "shared/model";
import {useAppDispatch, useAppSelector}          from "store";
import {
  selectCameraPermission,
  selectGalleryPermission,
  toggleCameraInApp,
  toggleGalleryInApp,
}                                                from "features/permissions";
import {GalleryRequest}                          from "features/permissions/gallery";
import {CameraRequest}                           from "features/permissions/camera";
import styles                                    from "./UploadMedia.module.scss";


interface Props {
  labels?: LabelItem[];
  description?: string;
}

function UploadMedia({description, labels}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [openSelect, setOpenSelect] = React.useState(false);
  const galleryPermission = useAppSelector(selectGalleryPermission);
  const cameraPermission = useAppSelector(selectCameraPermission);

  const handleToggleSelect = () => setOpenSelect(prev => !prev);

  const handleOpenCameraClick = () => {
    if (cameraPermission === null) return
    if (!cameraPermission) {
      return dispatch(toggleCameraInApp(true));
    } else {
      return setActionForNative(NativeActions.OPEN_BACK_CAMERA);
    }
  }

  const handleOpenGalleryClick = () => {
    if (galleryPermission === null) return;
    if (!galleryPermission) {
      return dispatch(toggleGalleryInApp(true));
    } else {
      return setActionForNative(NativeActions.OPEN_GALLERY);
    }
  }

  const [images, setImages] = React.useState<ImageFromNativeItem[]>([]);

  const handleCloseMediaUpload = () => {
    setOpenSelect(false);
    setImages([]);
    setActionForNative(NativeActions.MEDIA_CLEAN);
  }

  const handleUploadAgainClick = (uuid: string) => {
    setActionForNative(NativeActions.MEDIA_RETRY, {uuid});
  }

  useNativeHandler<ImageFromNativeItem>(null, NativeActions.MEDIA_TRANSFER, (data => {
    if (data) {
      setImages((prev) => {
        const exists = prev.some((item) => item.uuid === data.uuid);
        if (!exists) {
          return [...prev, {...data, isFailed: false}];
        }
        return prev;
      });
    }
  }));

  useNativeHandler<{ uuid: string, percent: number }>(null, NativeActions.MEDIA_PROGRESS, (data => {
    if (data) {
      setImages(prev => prev.map((media) => media.uuid === data.uuid ? {...media, percent: data.percent} : media));
    }
  }));

  useNativeHandler<{ uuid: string }>(null, NativeActions.MEDIA_FAILED, (data => {
    if (data) {
      setImages(prev => prev.map((media) => media.uuid === data.uuid ? {...media, isFailed: true} : media));
    }
  }));

  return <div className={styles.layout}>
    <div className={styles.title_holder}>
      <span className={styles.title}>{t("features.upload_media.title")}</span>
      {labels && <Label labels={labels} />}
    </div>

    <GalleryWrapper>
      <ul className={styles.media_wrapper}>
        <li>
          <button type="button" className={styles.upload_button} onClick={handleToggleSelect}>
            <span className={styles.add_icon}></span>
          </button>
        </li>

        {/*{images.map((media) => {*/}
        {/*  return <li key={media.uuid}>*/}
        {/*    <img width={92} height={92} src={media.base64} alt="" />*/}
        {/*  </li>*/}
        {/*})}*/}

        <InApp
          open={openSelect}
          onClose={handleCloseMediaUpload}
          headerText="Добавить файл"
          headerSubtext="0 из 5"
        >
          <div className={styles.inApp_content_wrapper}>

            {!!images.length && <ul className={styles.inApp_media_list}>
              {images.map((media) => {
                return <li key={media.uuid}>
                  <img width={92} height={92} src={`data:image/png;base64,${media.base64}`} alt="" />
                  {typeof media.percent === "number" && media.percent !== 100
                    && <div className={styles.media_percent_holder}>{`${media.percent ?? 69}%`}</div>}
                  {media.type === "video" && <div className={styles.video_icon} />}
                  {media.isFailed && <div className={styles.upload_error_holder}>
                    <Icon
                      onClick={() => handleUploadAgainClick(media.uuid)}
                      className={styles.media_error}
                    >
                      upload
                    </Icon>
                  </div>}
                  <button className={styles.remove_media}>delete</button>
                </li>
              })}
            </ul>}

            <NewList
              title="Сделать фото"
              icon={<Icon type={IconType.ROUNDED}>photo</Icon>}
              controlIcon={<Icon>keyboard_arrow_right</Icon>}
              onClick={handleOpenCameraClick}
            />

            <NewList
              title="Записать видео"
              icon={<Icon type={IconType.ROUNDED}>camera</Icon>}
              controlIcon={<Icon>keyboard_arrow_right</Icon>}
              onClick={handleOpenCameraClick}
            />

            <NewList
              title="Выбрать из галереи"
              icon={<Icon type={IconType.ROUNDED}>image</Icon>}
              controlIcon={<Icon>keyboard_arrow_right</Icon>}
              onClick={handleOpenGalleryClick}
            />

            <Button text="Готово" hasSpace />
          </div>
        </InApp>
      </ul>
    </GalleryWrapper>

    <span className={styles.description}>{description ?? t("features.upload_media.description")}</span>

    <CameraRequest />
    <GalleryRequest />
  </div>
}

export default React.memo(UploadMedia);

interface ImageFromNativeItem {
  uuid: string;
  type: "video" | "img";
  base64: string;
  percent?: number;
  isFailed?: boolean;
}