import {Route, Routes}         from "react-router-dom";
import {
  Registration,
  FF,
  Egov,
  TransferProfile,
  NotCitizen,
  WithDocument,
  ManualRegistration,
}                              from "pages/Registration";
import {TransferComplete}      from "pages/Registration/TransferComplete";
import {useTranslation}        from "react-i18next";
import SpecialistTariffsRoutes from "./SpecialistTariffsRoutes";
import CreditPagesRoutes       from "./CreditPagesRoutes";
import StatePagesRoutes        from "./StatePagesRoutes";
import {SpecialistReviews}     from "pages/Reviews/Specialist";
import {ClientReviews}         from "pages/Reviews/Client";
import {SelfReviews}           from "pages/Reviews/Self";
import {CashLoans}             from "pages/Specialist/loans";
import {Referral}              from "pages/customers/referral";
import {ClientProfile}         from "pages/client/profile";
import SpecialistProfileRoute  from "./SpecialistProfileRoutes";
import {SpecialistAppPrices}   from "../pages/client/specialist-prices";
import {CreateOrder}           from "../pages/order/create";


const RootRoutes = () => {
  const {t} = useTranslation();
  return <Routes>
    <Route path="/order-create" element={<CreateOrder />} />
    <Route path="/registration" element={<Registration />} />
    <Route path="/freedom" element={<FF />} />
    <Route path="/egov" element={<Egov />} />
    <Route path="/transfer" element={<TransferProfile />} />
    <Route path="/non-resident" element={<NotCitizen />} />
    <Route path="/document" element={<WithDocument />} />
    <Route path="/manual" element={<ManualRegistration
      navSubtitle={t("page.registration.manual_registration.header.description")}
      type="manual"
    />} />
    <Route path="/transfer-complete" element={<TransferComplete />} />
    <Route path="/specialist-reviews/:id" element={<SpecialistReviews />} />
    <Route path="/specialist-reviews/:id/:service_id" element={<SpecialistReviews />} />
    <Route path="/client-reviews/:id" element={<ClientReviews />} />
    <Route path="/specialist-self-reviews" element={<SelfReviews />} />
    <Route path="/specialist-self-reviews/:service_id" element={<SelfReviews />} />
    <Route path="/tariffs/*" element={<SpecialistTariffsRoutes />} />
    <Route path="/state/*" element={<StatePagesRoutes />} />
    <Route path="/credits/*" element={<CreditPagesRoutes />} />
    <Route path="/specialist-cash-loans" element={<CashLoans />} />
    <Route path="/referral" element={<Referral />} />
    <Route path="/profile/*" element={<SpecialistProfileRoute />} />
    <Route path="/client-profile/:id" element={<ClientProfile />} />
    <Route path="/specialist-prices/:id" element={<SpecialistAppPrices />} />
    <Route path="/specialist-prices/:id/:service_id" element={<SpecialistAppPrices />} />
  </Routes>
}
export default RootRoutes;