import React from "react";


function GeneralMark() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="42" height="68" viewBox="0 0 42 68" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 0C32.598 0 42 9.40202 42 21C42 31.9234 33.6599 40.8989 23 41.906V64C23 65.1046 22.1046 66 21 66C19.8954 66 19 65.1046 19 64V41.906C8.34014 40.8989 0 31.9234 0 21C0 9.40202 9.40202 0 21 0Z"
      fill="#FF1507"
    />
    <path
      d="M28 21C28 17.134 24.866 14 21 14C17.134 14 14 17.134 14 21C14 24.866 17.134 28 21 28C24.866 28 28 24.866 28 21Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M21 68C23.7614 68 26 66.8807 26 65.5C26 64.1193 23.7614 63 21 63C18.2386 63 16 64.1193 16 65.5C16 66.8807 18.2386 68 21 68Z"
      fill="#FF1507"
    />
  </svg>
}

export default React.memo(GeneralMark);