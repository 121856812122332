import React, {forwardRef, TextareaHTMLAttributes, useEffect, useRef} from "react";
import cn                                                             from "classnames";
import styles                                                         from "./Textarea.module.scss";
import {Icon}                                                         from "shared/ui";


interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hint?: string;
  errorMessage?: string;
  hasSymbolCounter?: boolean;
  counterCount?: number;
  isNew?: boolean;
  label?: string;
  isRequired?: boolean;
  hasSpace?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>(({
  hint,
  label,
  errorMessage,
  hasSymbolCounter = true,
  isNew = false,
  isRequired = false,
  counterCount,
  hasSpace = false,
  ...props
}, ref) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = !ref ? document.getElementById("textarea") : textareaRef.current;
    if (!textarea) return;

    const maxHeight = window.innerHeight * 0.5;
    textarea.style.height = "8.8rem";

    const initialHeight = textarea.style.height;

    const newHeight = textarea.scrollHeight <= maxHeight ? (textarea.scrollHeight > 120 ? textarea.scrollHeight : initialHeight) : maxHeight;
    textarea.style.height = `${newHeight}px`;
    textarea.style.overflowY = textarea.scrollHeight > maxHeight ? "scroll" : "hidden";
  };

  useEffect(() => {
    const textarea = !ref ? document.getElementById("textarea") : textareaRef.current;
    if (!textarea) return;

    textarea.addEventListener("input", handleInput);
    handleInput();

    return () => {
      textarea.removeEventListener("input", handleInput);
    };
  }, [textareaRef]);

  const textCounterCn = cn(
    styles.text_counter,
    {[styles.error]: textareaRef.current && textareaRef.current.value.length > 300},
  );

  const textareaCn = cn(
    styles.textarea,
    {[styles.error]: hasSymbolCounter && textareaRef.current && textareaRef.current.value.length > 300},
    {[styles.error]: !!errorMessage}
  );

  return <div className={cn(styles.wrapper, {[styles.has_space]: hasSpace})}>
    <div className={styles.textarea_holder}>
      {label && <span className={styles.label}>{label}{isRequired && <span className={styles.required}>*</span>}</span>}
      <textarea
        id="textarea"
        ref={ref ?? textareaRef}
        {...props}
        className={textareaCn}
        style={{height: "8.8rem", overflowY: "hidden"}}
      />
      {hasSymbolCounter && <span className={textCounterCn}>{`${counterCount ?? 0}/300`}</span>}
    </div>

    {!!hint && !errorMessage && <div className={cn(styles.hint_wrapper, {[styles.isNew]: isNew})}>
      {!isNew && <Icon className={styles.hint_icon}>info</Icon>}
      <span className={cn(styles.hint_text, {[styles.isNew]: isNew})}>{hint}</span>
    </div>}

    {!!errorMessage && <span className={styles.error_message}>{errorMessage}</span>}
  </div>
});

export default React.memo(Textarea);