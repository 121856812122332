import React      from "react";
import styles     from "./Icon.module.scss";
import {IconType} from "./model/iconType";
import cn         from "classnames";


interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  size?: number;
  iconColor?: string;
  type?: IconType;
}

function Icon({type = IconType.DEFAULT, iconColor, className, size, ...rest}: Props) {
  return <>
    {type === IconType.DEFAULT
      ? <span
        style={{color: iconColor ?? "", fontSize: size ? `${size}px` : ""}}
        className={cn(styles.icon, className)}
        {...rest}
      >
        {rest.children}
    </span>
      : <div className={styles.rounded_wrapper}>
        <span style={{color: iconColor ?? ""}} className={styles.rounded_icon}>{rest.children}</span>
      </div>
    }
  </>
}

export default React.memo(Icon);