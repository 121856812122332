import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {InApp}                                 from "shared/ui";
import {setActionForNative, useNativeHandler}  from "shared/lib";
import {NativeActions}                         from "shared/model";
import {useAppDispatch, useAppSelector}        from "store";
import {
  selectGeoInAppIsOpen,
  selectGeolocationPermission,
  setGeolocationPermission,
  toggleGeoInApp,
}                                              from "../model/permissionsSlice";


interface Props {
  setCurrentGeo: (value: number[]) => void;
  onClose?: () => void;
}

const i18nPrefix = "features.permissions.geolocation."
export default function GeolocationRequest({
  setCurrentGeo,
  onClose = () => {
  },
}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const geolocationPermission = useAppSelector(selectGeolocationPermission);
  const geoInAppIsOpen = useAppSelector(selectGeoInAppIsOpen);

  const handleCloseInAppClick = () => {
    onClose();
    dispatch(toggleGeoInApp(false));
  }

  const handleRequestGeoPermissionClick = () => {
    setActionForNative(NativeActions.GIVE_GEO_PERMISSION);
    dispatch(toggleGeoInApp(false));
  }

  React.useEffect(() => {
    if (geolocationPermission === null) {
      setActionForNative(NativeActions.HAS_PERMISSIONS_GEO);
    }
    if (geolocationPermission === true) {
      setActionForNative(NativeActions.GET_GEO_COORDS);
    }
  }, [geolocationPermission]);

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_GEO, data => {
    return dispatch(setGeolocationPermission(data.has_permissions));
  });

  useNativeHandler<{ longitude: number, latitude: number }>(null, NativeActions.GET_GEO_COORDS, data => {
    return setCurrentGeo([data.longitude, data.latitude]);
  });

  return <InApp
    inAppType="image"
    image="/illustrations/find-a-way-3.svg"
    headerText={t(`${i18nPrefix}text`)}
    headerSubtext={t(`${i18nPrefix}subtext`)}
    open={geoInAppIsOpen}
    onClose={handleCloseInAppClick}
  >
    <div className="inApp_buttons">
      <Button
        buttonColor={NewButtonColor.GRAY}
        buttonType={NewButtonType.WRAPPED}
        text={t("common.cancel")}
        onClick={handleCloseInAppClick}
      />

      <Button hasSpace text={t("common.allow")} onClick={handleRequestGeoPermissionClick} />
    </div>
  </InApp>
};