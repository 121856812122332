import React           from "react";
import {useNavigate}   from "react-router-dom";
import {PeriodItem}    from "../../../../../services/specialists";
import {NewList, Icon} from "shared/v12ui";


interface Props {
  period: PeriodItem;
}

function PeriodListItem({period}: Props) {
  const navigate = useNavigate();

  const handlePeriodClick = () => navigate(`/tariffs/options/${period.days}/${period.is_subscribe}`);

  return <NewList
    title={period.title}
    subtitle={period.subtitle}
    link={{name: period.price}}
    labels={period.labels}
    onClick={handlePeriodClick}
    controlIcon={<Icon className="text black">keyboard_arrow_right</Icon>}
  />
}

export default React.memo(PeriodListItem);