import React, {InputHTMLAttributes, useRef} from "react";
import styles                               from "./styles.module.scss";
import cn                                   from "classnames";


interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  disabled?: boolean;
  labelIcon?: React.ReactNode;
  isNew?: boolean;
}

export default function RadioInput({label, disabled, labelIcon, isNew = false, className, ...props}: Props) {
  const ref = useRef<HTMLInputElement>(null);

  const handleParentClick = () => {
    if (ref && ref.current) {
      ref.current.click();
    }
  }

  return <div onClick={handleParentClick} className={cn(styles.radio_wrapper, className, {[styles.is_new]: isNew})}>
    <div className={cn(styles.radio_label, {[styles.is_new]: isNew})}>{labelIcon && labelIcon}{label}</div>
    <div className={cn(styles.radio_input_wrapper,
      {[styles.is_new]: isNew},
      {[styles.radio_label_disabled]: disabled},
    )}
    >
      <input disabled={disabled} ref={ref} type="radio" {...{...props}} />
      <span className={cn(styles.radio_custom, {[styles.is_new]: isNew})}></span>
    </div>
  </div>
}